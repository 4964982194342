@tailwind base;
@tailwind components;
@tailwind utilities;

@import '/node_modules/material-symbols';
@import './utilities.global.scss';

/* You can add global styles to this file, and also import other style files */
$taxillaDarkGreen: #00a651;
$cancelButtonBgColor: #fff;
$matIconGreyColor: #616161;
$significantRegFontColor: #363636;
$settingsPageBackgroundColor: #f0f0f0;
$lightRedColor: #ff000080;
$hoverRedColor: rgba(255, 0, 0, 0.7);
$lineColor: #eaeaea;
$taxillaRedColor: #d81844;
$taxillaOrangeColor: #af7813;

$fontFamily: Lato, San Francisco, Proxima Nova, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Droid Sans, Helvetica Neue,
    Open Sans, sans-serif;

*,
::before,
::after {
    border-color: rgba(0, 0, 0, 0.54);
}

/* For the "inset" look only */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: unset;
    vertical-align: middle;
}

/* Let's get this party started */
.mat-paginator-page-size-label {
    min-width: 100px;
}

* {
    ::before,
    ::after {
        border-color: unset;
    }
}

app-main-root {
    display: block;
    height: 100%;
    width: 100%;
    // z-index: 1;
    position: relative;
}

html:focus-within {
    scroll-behavior: smooth;
}

html {
    overflow: hidden !important;
    scroll-behavior: smooth;
}

html,
body {
    cursor: default;
    width: 100%;
    margin: 0;
    overflow: hidden;
    height: 100%;
    text-shadow: none;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: 400;
    font-stretch: normal;
    font-size: 14px;
    line-height: 1.5;
    font-family: $fontFamily;
    -moz-font-smoothing: unset;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    background: #efefef;
}

/* IE10+ specific styles go here */
@media all and (-ms-high-contrast: active), all and (-ms-high-contrast: none) {
    body {
        overflow-x: hidden;
    }

    .matDialogContainer {
        position: static !important;
    }

    .mastersModalClass {
        height: auto !important;
    }

    button.appReportContainer {
        .mat-ripple {
            display: none !important;
        }
    }
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    outline-offset: 0;
    outline: 0;
    outline: none;
    -webkit-appearance: none;
    margin: 0;
    border: 0;
    padding: 0;
}

button:focus {
    outline: none;
}

mat-paginator {
    .mat-paginator-page-size {
        .mat-form-field-infix {
            width: auto !important;
            min-width: 50px;
            max-width: 80px;
        }
    }
}
.processDetailActionBtns {
    button {
        &.mat-primary,
        &.mat-warn,
        &.selectedProcessAction {
            .material-symbols-outlined,
            .material-icons-outlined,
            .material-icons {
                color: #fff;
            }
        }
    }
}

.enComplyMainContainer,
.enReportMainContainer,
.enInvoiceMainContainer,
.gstFilingMainContainer {
    .mat-flat-button.mat-primary.mat-button-disabled,
    .mat-flat-button.mat-accent.mat-button-disabled,
    .mat-flat-button.mat-warn.mat-button-disabled,
    .mat-flat-button.mat-button-disabled.mat-button-disabled,
    .mat-raised-button.mat-primary.mat-button-disabled,
    .mat-raised-button.mat-accent.mat-button-disabled,
    .mat-raised-button.mat-warn.mat-button-disabled,
    .mat-raised-button.mat-button-disabled.mat-button-disabled,
    .mat-fab.mat-primary.mat-button-disabled,
    .mat-fab.mat-accent.mat-button-disabled,
    .mat-fab.mat-warn.mat-button-disabled,
    .mat-fab.mat-button-disabled.mat-button-disabled,
    .mat-mini-fab.mat-primary.mat-button-disabled,
    .mat-mini-fab.mat-accent.mat-button-disabled,
    .mat-mini-fab.mat-warn.mat-button-disabled,
    .mat-mini-fab.mat-button-disabled.mat-button-disabled {
        background-color: rgba(0, 0, 0, 0.12);
    }

    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
        background-color: $taxillaDarkGreen;
        color: #fff;
    }

    #mainRouterContainer {
        min-width: 1170px;
        margin: auto;
        height: 100%;

        &.device-desktop {
            min-width: 1200px;
        }

        &.device-handset {
            &.app-responsive {
                min-width: 100%;
                max-width: 1199px;
                height: 100%;
            }
        }
    }

    @media only screen and (min-width: 1056px) and (max-width: 1366px) {
        #mainRouterContainer {
            min-width: unset;
        }
    }

    .loginDetailsSection {
        // border-bottom: 1px solid #ccc;
        // margin: 30px 25px 0;
        font-size: 14px;
        padding: 20px 30px;
    }

    .loginDetailsSection .row {
        margin-bottom: 20px;
    }

    .loginDetailSection {
        -webkit-backface-visibility: hidden !important;
        backface-visibility: hidden !important;
        width: 96%;

        &.ldapMode {
            .loginSection {
                & > div {
                    margin-top: 40px;
                }
            }
        }
    }

    &.onNewUI > app-main-root {
        & > #mainRouterContainer {
            & > #postLoginContainer,
            & > #preLoginContainer {
                background-repeat: no-repeat;
                background-image: url(assets/images/new-ui/wall.png);
                background-color: #ffffff;
                background-size: cover;
            }

            & > #postLoginContainer {
                width: calc(100% - 60px);
                display: inline-block;
                height: 100%;
                vertical-align: top;

                &.inGuestView {
                    width: 100%;
                }
            }
        }
    }
}

.mat-optgroup .mat-option.checkSumMatMenuOption:not(.mat-option-multiple) {
    padding-left: 20px;

    .checkSumValue {
        width: calc(100% - 26px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        display: inline-block;
    }

    mat-icon {
        margin-right: 0;

        &:hover {
            color: $matIconGreyColor;
        }
    }
}

button[aria-label='Description'] span.material-icons-outlined {
    color: #d0d0d0;
}

.btn:focus,
.btn.focus {
    box-shadow: none;
}

.confirmationDialog {
    width: 30% !important;
}

.tenantsList .cdk-virtual-scroll-content-wrapper,
.tenantFlatStructure .cdk-virtual-scroll-content-wrapper {
    box-shadow: none !important;
    border: none !important;
}

.loaderImageContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1052;

    .loader {
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 1;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .loaderMessage {
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 1;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        color: #000;
        font-weight: 900;
        min-width: 100px;
        margin-top: 60px;
    }
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $taxillaDarkGreen;
}

.snackBarMainDiv {
    .actionText {
        margin-left: 5px;
    }

    .snackCloseBtnIcon {
        top: 3px;
        margin-left: 10px;
        font-size: 20px !important;
    }

    .snackbarIcon {
        font-size: 12px !important;
        font-weight: bold !important;
    }
}

.cursorPointer {
    cursor: pointer;
}

.submitSignup {
    margin-top: 20px;

    button.mat-raised-button.mat-primary {
        width: 100%;
        padding: 5px 0;
        font-size: 18px;
    }
}

.resendSubmit {
    button.mat-raised-button.mat-primary {
        width: 48%;
        padding: 5px 0;
        font-size: 16px;
    }
}

.signupLink {
    margin-top: 10px;

    .btn-link {
        color: #47a651;
        text-decoration: none;
        outline: none;
    }
}

.signupCaptchaImg {
    // margin-right: 20px;
    min-width: 100px;
    // min-height: 60px;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 40px);
    margin: 0px 7px 12px;
    text-align: right;

    img {
        height: 45px;
    }
}

.signupCaptchaLabel {
    padding-top: 15px;

    .cursorPointer {
        cursor: pointer;
    }
}

.padding25pxComplete {
    padding: 25px !important;
}

.marginTop10 {
    margin-top: 10px;
}

.pdbtm5 {
    padding-bottom: 5px;
}

.pdbtm15 {
    padding-bottom: 15px;
}

.loaderBlock.isLoading {
    background: -webkit-gradient(linear, right top, left top, from(#d0d0d0), to(#9e9e9e));
    background: linear-gradient(270deg, #d0d0d0, #9e9e9e);
    background-size: 400% 400%;
    -webkit-animation: GradientDualToneAnimation 2s ease infinite;
    animation: GradientDualToneAnimation 2s ease infinite;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none !important;
}

.visibilityHidden {
    visibility: hidden !important;
}

.signupTerms .validateOptionsDiv span,
.partnerTerms .validateOptionsDiv span {
    color: #f44c3b !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-align: left;
    padding: 0;
    display: inline-block;
    height: 15px;
    line-height: 15px;
    margin-left: 0;
}

.signupSubInfoContainer {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.signupInfoContainer {
    // background: #e4e4e4;
    background: #f5f5f5;
}

mat-card.signupCard.mat-card {
    padding: 0;
}

.signupDetailsContainer {
    width: 84.7%;
    margin: 0 auto;
    font-size: 14px;

    .mat-card-title {
        font-weight: 400;
        margin-top: 20px;
        color: #353535;
    }
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    font-size: 1.3em !important;
}

.signupInfoSubContainer {
    position: relative;
    height: 100%;
    // background: #e4e4e4;
    background: #f5f5f5;
}

.signupSubInfoLogo img {
    margin: 10px 0;
    height: 36px;
}

.signupSubInfoTitle mat-card-title {
    font-weight: 400;
    margin-top: 20px;
    color: #353535;
}

.signupInfoBorder {
    border-bottom: 5px solid #49a850;
    width: 35%;
    margin: 0 auto;
    margin-top: 60px;
}

.signupSubInfoLogo {
    margin-bottom: 80px;
}

.subscriptionItemTitle {
    color: #42a060;
    font-size: 30px;
    font-weight: 300;
}

.signupRedirectToLogin {
    text-align: center;
    margin: 20px 0;
    font-size: 13px;
}

.signupDetailsContainer .btn-link {
    color: #47a651;
    font-weight: 500;
    font-size: 13px;
    text-decoration: none;
    outline: none;
}

.modal-dialog.eula {
    max-width: 100%;
    width: 75%;
}

.modal-footer.eula material-button {
    margin: 0 auto;
}

#preLoginContainer,
#postLoginContainer,
#profileHeaderContainer,
.ForgotpasswordPage {
    height: 100%;
}

#preLoginContainer,
.autheticationContainer {
    background-repeat: no-repeat;
    background-image: url(./assets/images/bg.png);
    background-color: #ffffff;
    background-size: cover;
    width: 100%;
    background-position: bottom;

    &.onNewUI {
        background-image: url(./assets/images/new-ui/wall.png);

        #preLoginContainer {
            background-image: url(./assets/images/new-ui/wall.png);
        }
    }
}

.signupContainer {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    margin: 100px 0;

    .signupCard > .row {
        margin: 0;
    }
}

.securityAuthenticateMatContainer {
    // text-align: center;
    background: $cancelButtonBgColor;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 50%;
}

.setPasswordPage {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;

    .setPasswordContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: 75%;
        height: auto;

        mat-card.mat-card {
            padding: 0;
        }

        .setPasswordMainContainer {
            .setPasswordInfoContainer {
                padding-right: 0;

                .setPasswordWelcomeContainer {
                    position: relative;
                    // overflow: auto;
                    height: 100%;
                    width: 100%;
                    background: #e4e4e4;

                    .setPasswordSubWelcomeContainer {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translateX(-50%) translateY(-50%);
                        transform: translateX(-50%) translateY(-50%);
                        padding: 10px;
                        width: 100%;
                        text-align: center;

                        .signupSubInfoLogo {
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            .setPasswordDetailsContainer {
                padding-top: 15px;
                padding-right: 30px;

                .setPasswordTitle {
                    text-align: center;
                    color: #353535;
                    margin-bottom: 20px;
                }

                .setPassword_detailBlock {
                    margin: 10px 0;
                    font-size: 14px;
                }

                .setPasswordNonCapturedDetails {
                    padding: 0 15px 10px;

                    .infoContainer {
                        position: absolute;
                        top: 34px;
                        display: inline-block;
                        color: #7d7d7d;

                        mat-icon {
                            width: 20px;
                            height: 20px;
                            font-size: 20px;
                        }
                    }
                }

                .securityQuestionsDiv {
                    margin: 0px 10px;
                }

                .setPasswordSubmitContainer {
                    margin-top: 10px;

                    button.mat-raised-button.mat-primary {
                        width: 25%;
                        max-width: 200px;
                    }

                    .signupRedirectToLogin .btn-link {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

#signupPageContainer {
    width: 100%;
    height: 100%;
    position: relative;
    // background: #a9daab;
    overflow: auto;
    overflow-x: hidden !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    /* Scroll */
    ::-webkit-scrollbar {
        width: 11px;
        height: 11px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background: #ccc;
        min-height: 60px;
        box-shadow: inset 0 0 3px #827f7f6b;
    }

    /* Track */
    // use .noTrack class on an element that you don't want scroll track
    :not(.noTrack)::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px #827f7f99;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: #827f7f6b;
    }
}

.cssmarqueeCntr {
    padding-right: 17px;
    position: relative;
    background: #d9e7dd;

    .closeImpMsg {
        position: absolute;
        top: 12px !important;
        right: 0px !important;
        cursor: pointer;
        font-size: 20px;

        &:hover {
            background: #f1f1f1;
        }
    }
}

.cssmarquee {
    height: 45px;
    overflow: hidden;
    position: relative;
    background: #d9e7dd;

    h1 {
        font-size: 1.6em;
        color: #881212;
        position: absolute;
        min-width: 100vw;
        height: 100%;
        margin: 0;
        line-height: 45px;
        text-align: center;
        transform: translateX(100%);
        animation: cssmarquee 20s linear infinite;

        &:hover {
            animation-play-state: paused;
        }
    }
}

@keyframes cssmarquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.matDialogBorderBottom {
    position: relative;
    top: -12px;

    .material-icons {
        cursor: pointer;
    }
}

.matDialogTitle {
    margin-bottom: 7px !important;
    font-weight: bold !important;
}

.fetchAssetModalParent {
    width: 90%;
    max-width: 90% !important;

    .fetchAssetModal {
        padding: 16px;

        .myAllApps {
            padding: 0px;
            margin: 0px;

            .menuItem:hover {
                background: #f7f5f5;
            }
        }
    }
}

.matDialogContainer {
    position: absolute;
    top: 10%;
}

.editPopupWidth {
    width: 80%;
}

.addRecordsDialog {
    width: 98%;
    max-width: 100vw !important;
    height: 96%;

    .mat-dialog-container {
        padding: 0 10px !important;
    }
}

.newProcessNotificaionModal {
    width: 38%;
    height: 30%;
}

.masterCloseBtnIcon {
    position: relative;
    top: 4px;
    cursor: pointer;
}

// mat-dialog-actions:not(.noBorder) {
//     border-top: 1px solid lightgrey;
// }

.sessionExpiredModal {
    width: 30% !important;

    .sessionContentDiv {
        font-size: 14px;
    }
}

.enComplyMainContainer.autheticationContainer {
    .routerOutlet {
        height: 100vh;
    }
}

.passwordExpiryDialog {
    width: 30%;

    .passwordContentDiv {
        font-size: 15px;
    }
}

.allProcessActionButtonDialog {
    width: 50%;
    max-width: 100vw !important;
    height: 65%;

    .mat-dialog-container {
        padding: 0 10px !important;
    }
}

.historyMenuContainer {
    max-width: none !important;
    width: 25vw !important;
    margin-top: 0px !important;
    border-radius: 0px !important;
    padding: 0 !important;
    overflow: hidden !important;
    margin-right: -75px;
    margin-bottom: 10px;
    border-bottom: 2px solid $taxillaDarkGreen;
    max-height: 100% !important;

    .mat-menu-content {
        max-height: 100% !important;
    }

    .historyItemsTitle {
        padding: 5px;
        font-size: 16px;
        text-align: center;
        border-bottom: 1px solid #e4e4e4;
    }

    .historyItemsContainer {
        max-height: 75vh;
        overflow-y: auto;

        .historyItemContainer {
            margin: 0;
            cursor: pointer;
            padding: 5px;
            border-bottom: 1px solid #e4e4e4;

            &:hover {
                background: #f1f1f1;
                border-radius: 3px;
                box-shadow: inset 0 0 3px #827f7f6b;
                border-bottom: 1px transparent;
            }

            &.activeRoute {
                background: #f1f1f1;
            }

            &:last-child {
                border-bottom: 1px transparent;
            }

            .itemIconContainer.col-1 {
                margin: 0;
                padding: 2px 0 0 0;
            }

            .itemDetailsContainer {
                margin: 0;
                padding: 0 0 0 15px;

                .material-icons-outlined {
                    vertical-align: middle;
                }
            }
        }
    }

    &:after {
        content: '';
        border-top: 10px solid $taxillaDarkGreen;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        right: 10px;
    }
}

.collaboratorsListDiv {
    margin: auto;

    .collaborator {
        padding: 15px;
        margin: 15px;
        border: 1px solid #d8d8d8;

        &:nth-child(even) {
            background: #f7f7f7;
        }

        .readOnlyLabel {
            color: #9c9c9c;
            font-size: inherit;
            text-align: right;
            line-height: 24px;
            padding-right: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .readOnlyValue {
            font-size: 13px;
            line-height: 24px;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            padding-right: 15px;
        }

        .collaboratorPartner {
            position: absolute;
            margin: -3px 9px;
        }

        mat-icon {
            font-size: 21px;
        }
    }
}

.collaborationContainer {
    padding: 15px;
}

.settingsHeader {
    padding: 10.5px;
    font-weight: bold;
    text-transform: capitalize;
    border-bottom: 1px solid #ccc;

    .settingsNameText {
        display: inline-block;
        line-height: 17px;
        padding-left: 5px;
        font-size: 15px;
    }

    .colonText {
        margin-right: 3px;
    }

    .settingsDataActionsContainer {
        float: right;
        text-align: right;
        position: relative;
        top: -12px;
        right: 5px;

        .outlookSearchContainer {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            font-weight: 400;
            text-align: left;

            .mainSearch {
                height: 41px;
                padding: 5px;

                .mainSearchSearcher {
                    position: relative;
                    padding: 0 0.5em;
                    height: 32px;
                    min-width: 250px;
                    -webkit-transition: all 300ms ease;
                    transition: all 300ms ease;

                    .searchIcon {
                        font-size: 19px;
                        color: $matIconGreyColor;
                    }
                }

                .searchBorder {
                    border: 1px solid lightgray;

                    input {
                        width: calc(100% - 20px);
                        outline: none;
                        border: 0;
                    }
                }
            }

            .masterMainSearch {
                padding: 0 !important;
            }
        }

        .addIcon {
            position: relative;
            top: 0;
            cursor: pointer;
        }

        .actionButtons {
            span.material-icons.hasError {
                font-size: 24px;
                padding-right: 0;
            }
        }
    }

    .closeIcon {
        position: relative;
        top: 8px;
        cursor: pointer;
    }
}

#manageMaster {
    .masterContainer {
        .drawerContainer {
            width: 100%;
            border-radius: 0px;

            .masterMatDrawer {
                width: 100%;
                border-radius: 4px;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;

                .masterField {
                    display: inline-block;
                }
            }
        }

        .flexFormContainer {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding-left: 15px;

            .inboundIntegrationFieldContainer {
                padding-left: 5px;
                padding-right: 5px;
                margin-left: 0;
                margin-right: 0;
                max-width: 20%;
            }

            .inboundIntegrationFieldContainer:first-child {
                padding-left: 0;
            }

            .inboundIntegrationFieldContainer:last-child {
                padding-right: 0;
            }
        }

        .mat-expansion-panel {
            -webkit-box-shadow: none;
            box-shadow: none;
            border-bottom: 1px solid $lineColor;
            border-radius: 0px;

            .mat-expansion-panel-header {
                padding: 0px 7px 0px 0px;
            }
        }

        .parentOption {
            padding-left: 0px;
            height: 30px;
            font-weight: 600;
        }

        .childOption {
            padding-left: 30px;
            height: 30px;
            display: inline-block;
            font-size: 14px;
        }

        .details .mat-raised-button.mat-primary {
            background-color: #b8b8b8 !important;
            outline: none;
            margin: 0px;
        }

        .cancel .mat-raised-button.mat-primary {
            background-color: $cancelButtonBgColor !important;
            color: rgba(0, 0, 0, 0.87) !important;
            outline: none;
            margin-right: 10px;
        }

        .mat-drawer-inner-container {
            height: 100%;
            background: $cancelButtonBgColor;
        }

        .masterCard {
            width: 100%;
            min-height: inherit;
            border-radius: 0px;
            margin-bottom: 15px;
            -webkit-box-shadow: none;
            box-shadow: none;
            padding: 15px;

            .actionButton.mat-icon-button {
                mat-icon:not(.disable) {
                    color: $matIconGreyColor;

                    &:hover {
                        color: $taxillaDarkGreen;
                    }
                }

                &.clearSearch {
                    mat-icon:not(.disable) {
                        color: $lightRedColor;

                        &:hover {
                            color: $hoverRedColor;
                        }
                    }
                }
            }

            .backBtn {
                cursor: pointer;

                .actionButton.mat-icon-button {
                    width: 25px;
                }

                &:hover {
                    color: $taxillaDarkGreen;

                    mat-icon {
                        color: $taxillaDarkGreen !important;
                    }
                }

                mat-icon {
                    font-size: 1.5em;
                }
            }

            .mastersContainerRow {
                width: 100%;

                .masterData {
                    .masterTable {
                        width: 100%;
                        padding: 10px 0px;

                        .matTableContainer {
                            overflow: hidden !important;

                            .tableContainerWithoutPaginator {
                                overflow-x: auto;

                                .actionButtons {
                                    width: 24px;
                                    height: 24px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .mat-card-title {
                font-size: 16px;
                font-weight: bolder;
                border-bottom: 1px solid #ebebeb;
                height: 50px;
                overflow: hidden;
            }

            .uploadTable {
                border: 1px solid #dee2e6;
            }

            .uploadTable thead {
                background-color: $settingsPageBackgroundColor;
            }

            .uploadTable thead th {
                border-bottom: 1px solid #dee2e6;
            }

            .outbound {
                display: inline-block;
            }

            .downloadFile {
                color: #ccc;
            }

            .downloadFile:hover {
                color: $taxillaDarkGreen;
                text-decoration: underline;
                cursor: pointer;
            }

            .checksumDownload {
                float: right;
            }

            .searchMastersDiv {
                margin-top: -10px;
                max-width: 25%;
            }

            .searchMasters {
                padding: 0 16px;
                font-size: 12px;
            }

            .mastersHeader {
                padding-top: 8px;
                -webkit-box-flex: 1;
                -ms-flex: auto;
                flex: auto;
            }

            .tablePosition {
                margin: 20px 0px;
                padding-right: 5px !important;
            }

            .masterTileContailer {
                padding: 10px;
                float: left;
                display: grid;

                .masterTiles {
                    border: 1px solid #ececec;
                    text-align: left;
                    height: 80px;
                    padding: 10px;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    width: 100% !important;
                }

                .masterName {
                    font-weight: bold;
                    margin: 0 5px;
                    width: 89%;
                    display: table;
                    /* padding-top: 20px; */
                    height: 100%;

                    .masterText.loaderBlock.isLoading {
                        height: 20px;
                        display: inline-block;
                        margin-top: 18px;
                        width: 200px;
                    }

                    .masterText {
                        vertical-align: middle;
                        display: table-cell;
                        font-size: 16px;
                        font-weight: bold;
                        color: $significantRegFontColor;
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .infoContainer {
                    margin-bottom: 0px !important;
                    height: 24px;
                    padding-top: 15px;

                    .info {
                        color: #b8b8b8;
                        cursor: pointer !important;
                    }
                }

                .masterTiles:hover {
                    -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
                    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
                    border: 1px solid $taxillaDarkGreen;
                    cursor: pointer;
                }

                .info:hover {
                    color: #7d7d7d;
                    cursor: default;
                }

                .details:hover {
                    .mat-raised-button.mat-primary {
                        background-color: $taxillaDarkGreen !important;
                    }
                }
            }

            .masterFooter {
                text-align: right;
                display: inline-block;
                padding-right: 0px;

                button {
                    margin: 0 5px;
                }
            }

            .noRecords {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: 15%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 16px;
            }

            .noRecords span.material-icons {
                color: $matIconGreyColor;
                font-size: 2.2em;
            }

            .masterChildContainer {
                padding: 0px 0px 5px 15px;

                .masterFieldContainer {
                    min-height: 430px;
                }

                .radioContainer {
                    margin: 20px 0px;
                    display: inline-block;

                    .radioItem {
                        padding: 0px;
                        margin: 0px;
                        display: inline-block;
                        color: #9c9c9c !important;

                        .radioGroup {
                            padding: 0px;
                            margin: 0px;
                            display: inline-block;

                            .radioButton {
                                display: inline-block;
                                margin: 5px;
                            }
                        }
                    }
                }

                .childMasterRow {
                    padding: 0px;
                    margin: 0px;

                    .noMasterRecordContainer {
                        width: 100%;
                    }

                    .position {
                        padding-left: 0px;
                    }
                }

                .childAction {
                    float: right;
                    width: auto;
                    padding: 0px;
                    margin: 0px 0px 18px;

                    .searchFilter {
                        padding: 0px;
                        margin: -20px 2px 0px 2px;
                        width: 30%;
                        float: right;
                    }

                    .material-icons {
                        vertical-align: middle !important;
                    }

                    .actionButton {
                        margin: 0px 2px 0px 2px;
                        float: right;
                        line-height: 20px;
                        max-width: 27px;
                        max-height: 26px;
                    }

                    .searchIcon {
                        font-size: 30px;
                        height: 36px;
                        padding-top: 3px;
                        border-radius: 5px;
                        width: 40px;
                        background-color: #63ab66;
                        color: white;
                        padding-left: 5px;
                    }

                    .masterDataSettings {
                        visibility: none !important;
                    }
                }
            }

            #inboundIntegrationsPageContainer {
                .inboundIntegrationsContainer {
                    .inboundBox,
                    app-integrations-template {
                        box-shadow: none !important;
                    }
                }
            }
        }

        app-common-masters {
            .notifyContentContainer {
                height: calc(100vh - 118px) !important;
                position: relative;
            }
        }
    }
}

.leftNavMenu {
    position: fixed !important;
    top: 0px !important;
}

#manageMaster #settingsContainer .drawerContainer {
    overflow: visible !important;
}

#manageMaster .masterContainer .mat-drawer-inner-container .slimScrollDiv .slimScrollBar {
    display: none !important;
}

.newCustomPagination {
    width: 100%;
    height: 61px;
    display: flex;
    justify-content: flex-end;

    .newCustomPaginationUl {
        display: inline-block;
        margin: 0px;

        .newCustomPaginationLi {
            display: inline-block;
            margin: 8px 8px 0px;
        }

        .newCustomPaginationArrows {
            vertical-align: middle;
        }

        .leftArrow {
            cursor: pointer;
            margin-right: 20px;
        }

        .rightArrow {
            cursor: pointer;
        }

        .disable {
            cursor: not-allowed;
            color: rgba(0, 0, 0, 0.38) !important;
        }
    }
}

.notifyContentContainer {
    padding: 10px;

    .eventLogHedderDiv {
        padding: 5px 15px 15px;
    }
}

.elasticSearchModal {
    max-width: 900px;
    min-height: 550px;
    overflow: auto;
    pointer-events: auto;
    font-size: 14px;
    min-width: 65%;

    .matDialogBody {
        min-height: 400px;
    }

    .masterSearchBtn {
        margin-left: 10px;
    }

    .masterSearchDialogBody {
        background: $settingsPageBackgroundColor;
    }

    .qbSection {
        margin-bottom: 10px;
        padding-bottom: 10px;
        margin-left: 0;
        margin-right: 0;

        h4 {
            font-size: 1rem;
            font-weight: bold;
        }

        .loader {
            position: absolute;
            left: 50%;
            top: 50%;
            opacity: 1;
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .sortQbSection {
        h4 {
            font-size: 1rem;
            font-weight: bold;
        }
    }

    .cancel .mat-raised-button.mat-primary {
        background-color: $cancelButtonBgColor !important;
        color: rgba(0, 0, 0, 0.87) !important;
        outline: none;
        margin-right: 10px;
    }

    .mat-radio-checked .mat-radio-label-content {
        font-weight: 600;
    }
}

.floatRight {
    float: right;
}

#inboundIntegrationsPageContainer {
    height: unset !important;

    .inboundIntegrationsContainer {
        width: 100%;
        height: 100%;
        position: relative;
        font-weight: 300;
        color: #141414;
        font-size: 14px;

        .inboundIntegrationsHeaderContainer {
            border-bottom: 1px solid #ebebeb;
            overflow: hidden;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-left: 20px;
        }

        .inboundIntegrationsHeaderText {
            padding: 20px 15px 15px 0px;
            font-weight: bold;
            font-size: 16px;
            float: left;
            -webkit-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
        }

        .actionIcon {
            margin-top: 0px;
            margin-right: 15px;
        }

        .inboundIntegrationsBodyContainer {
            padding: 30px;
            overflow: auto;

            .inboundIntegrationsFormContainer {
                .inboundIntegrationFieldContainer {
                    margin-top: 5px;
                    -webkit-box-flex: 1;
                    -ms-flex: auto;
                    flex: auto;
                    margin-bottom: 5px;
                    padding: 0 15px;

                    mat-radio-group.inputRadioGroup {
                        -webkit-box-orient: horizontal;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: row;
                        flex-direction: row;

                        mat-radio-button {
                            margin: 0 10px;
                        }
                    }
                }

                .configAddsModalContainer {
                    float: right;
                    cursor: pointer;

                    .addConfigSpan {
                        margin-right: 15px;
                        cursor: pointer;
                    }

                    span {
                        color: $taxillaDarkGreen;
                    }

                    span:hover {
                        border-bottom: 1px solid $taxillaDarkGreen;
                    }

                    .integrationFieldsContainer {
                        .integrationRecordFieldContainer {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            margin: 10px 0;

                            .integrationRecordField.integrationArrayTextField {
                                .integrationRecordArrayTextFieldContainer {
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    display: flex;

                                    .integrationRecordTextFieldContainer {
                                        width: 45%;
                                        -webkit-box-flex: 1;
                                        -ms-flex: auto;
                                        flex: auto;
                                    }
                                }
                            }

                            .integrationRecordTextFieldActions {
                                -webkit-box-flex: 1;
                                -ms-flex: auto;
                                flex: auto;
                                text-align: center;
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                line-height: 66px;

                                .integrationRecordFieldActionText {
                                    mat-icon {
                                        vertical-align: middle;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .integrationRecordField {
                                -webkit-box-flex: 1;
                                -ms-flex: auto;
                                flex: auto;

                                mat-radio-group.inputRadioGroup {
                                    -webkit-box-orient: horizontal;
                                    -webkit-box-direction: normal;
                                    -ms-flex-direction: row;
                                    flex-direction: row;

                                    mat-radio-button {
                                        margin: 0 10px;
                                    }
                                }

                                label.fieldLabel {
                                    line-height: 36px;
                                }

                                .integrationFieldArrayTextLabel {
                                    line-height: 66px;
                                }

                                material-dropdown {
                                    button.mat-button {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .integrationRecordSubmitContainer {
                        text-align: right;
                        margin-bottom: 20px;
                        padding-right: 40px;

                        .mat-raised-button {
                            width: 150px;
                            margin: 0 5px;
                        }
                    }
                }

                .flexFormContainer {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;

                    .inboundIntegrationFieldContainer {
                        padding-left: 5px;
                        padding-right: 5px;
                        margin-left: 0;
                        margin-right: 0;
                    }

                    .inboundIntegrationFieldContainer:first-child {
                        padding-left: 0;
                    }

                    .inboundIntegrationFieldContainer:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        .inboundIntegrationsFooterContainer {
            text-align: right;
            padding-bottom: 15px;
            padding-right: 20px;

            button {
                margin: 0 5px;
            }
        }

        .mat-drawer-side.mat-drawer-end {
            border: none;
        }
    }
}

.subSectionRecordContainer {
    border: 1px solid #c1c1c1;
    padding: 25px;
    margin: 10px 0;
    position: relative;
    padding-bottom: 35px;
}

.subSectionActionsContainer {
    float: right;
    margin-right: 10px;
}

.subSectionActionContainer {
    cursor: pointer;
}

.inboundIntegrationsFieldsContainer {
    .subSectionDisplayName {
        font-weight: 600;
        margin: 15px 0;
        color: inherit;
    }
}

.removeSubRecordAction {
    position: absolute;
    right: 5px;
    cursor: pointer;

    mat-icon.mat-icon {
        color: red;
    }

    .mat-raised-button {
        line-height: 28px;
    }
}

.removeSubRecordAction.actionsAtBottom {
    bottom: 3px;
    margin: 5px;

    .mat-raised-button {
        padding: 0 7px;

        .mat-button-wrapper {
            margin-left: -2px;
        }
    }
}

.removeSubRecordAction.actionsAtTop {
    top: 3px;
    margin: 5px;

    .mat-raised-button {
        padding: 0 7px;
        background: $cancelButtonBgColor !important;
        color: #707070 !important;
        border: 1px solid #c1c1c1 !important;
    }

    button.mat-raised-button.mat-default:hover {
        background-color: $taxillaRedColor;
    }

    .material-icons {
        font-size: 18px;
        margin-top: -3px;
        margin-left: -3px;
    }
}

.synchronizeMasterCard {
    padding: 0px !important;
}

.uploadFileName {
    width: 50% !important;
    margin-left: 15px;
}

.uploadFormat {
    width: 20%;
}

.uploadTd {
    vertical-align: middle !important;
}

.masterDateFilter {
    cursor: pointer;
    margin-right: 20px;

    .mainSearchFilters {
        width: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0px;
        z-index: 2;
        right: 0px;
        transition: 0.5s;
    }

    .mainSearchFilters.filterVisible {
        height: fit-content;
        width: 50%;
        z-index: 9999;

        .srchHeading {
            position: relative;
            height: 42px;
            padding: 10px 10px 10px 20px;
            border-bottom: 1px solid #a6a6a691;

            .srchHeadingLabel {
                font-size: 15px;
            }
        }
    }

    .selectedFilterContainer {
        height: calc(100% - 110px) !important;
        overflow: hidden;
        padding: 1.2em;
        padding-left: 20px;
    }

    .actionBtnsSection {
        border-top: 1px solid #ccc;
    }
}

.masterRequestsTable {
    .masterDateSearchDiv {
        margin-top: -12px;
        .clearSearchSuffix {
            color: #da534e;
            font-size: 14px;
            padding: 0;
            display: inline-block;

            &:hover {
                border-bottom: 1px solid #da534e;
            }
        }

        .searchModeSuffix {
            font-size: 14px;
            padding: 0;
            display: inline-block;
        }
    }

    .settingsDataActionsContainer {
        .refreshIcon {
            margin-right: 15px;
        }
    }

    .masterCard {
        width: 100%;
    }
}

.searchFilters {
    color: $matIconGreyColor;
    line-height: 48px;

    &:hover {
        text-decoration: underline;
    }
}

.searchFilterDropdown {
    .searchFilterOption {
        position: relative;
    }

    mat-icon {
        color: $matIconGreyColor;

        &:hover {
            color: $hoverRedColor;
            cursor: pointer;
        }

        position: absolute;
        right: 5px;
        top: 0.5em;
    }
}

.mat-menu-content {
    height: 100%;

    .messagesMainContainer {
        height: 100%;
        padding: 10px;
        position: relative;

        .mat-menu-head {
            font-size: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid #d6d6d6;

            &.errors {
                color: $taxillaRedColor;
            }

            &.warnings {
                color: $taxillaOrangeColor;
            }

            .processesCloseInstances {
                position: absolute;
                right: 10px;
                top: 11px;
            }

            .messageCopyContainer {
                position: absolute;
                right: 40px;
                cursor: pointer;

                .mat-icon {
                    font-size: 24px;
                }
            }
        }

        .mat-menu-body.messagesContainer {
            height: calc(100% - 38px);
            overflow-y: auto;
            font-size: 13px;
            margin: 5px 0px 10px;

            .messageContainer {
                margin: 10px 0;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.hasWarnings {
                height: auto;
                min-height: 60px;
                max-height: 300px;
            }
        }
    }
}

.masterNameSpan {
    font-weight: 500;
}

.allMastersList {
    .mastersSearch {
        .mat-icon-button {
            font-size: 20px !important;
        }
    }
}

.signupTerms {
    margin-top: 15px;
}

.displayInlineBlock {
    display: inline-block;
}

.dataTypeTextAlign {
    display: block;
    text-align: right;
    padding-right: 20px;
}

.fieldFileUploadModal {
    width: 70%;
    height: auto;
    margin: 0;
    transition: none !important;

    .fieldAttachmentRow {
        margin: 0 0 10px;
        line-height: 36px;

        .invoiceUploadButton {
            line-height: 21px;
            left: 0px !important;
        }

        & > span {
            &:first-child {
                margin-right: 10px !important;
            }

            &:last-child {
                margin-left: 10px;
            }
        }
    }

    .fieldAttachedFileName {
        line-height: 36px;
        margin: 0;

        .fileNameLabel {
            margin-right: 15px;
            margin-left: 0px !important;
        }

        .fileNameValue {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .fileTypeSupport {
        color: #a94442;

        span {
            margin-top: 5px;
            margin-bottom: 20px;
        }
    }

    .maxAllowedFileCount {
        margin: 10px 30px;

        .alreadyReached {
            color: #a94442;
        }
    }

    .close.noOutline {
        position: absolute;
        top: 50%;
        right: 3%;
        transform: translateY(-50%);
        padding: 0;
        float: none;
        margin: 0;
    }

    .uploadedFilesListContainer {
        font-weight: normal;
        font-size: 14px;
        margin-top: 10px;
    }

    .uploadedFilesListHeader {
        & > div {
            padding: 15px 0 15px 15px;
            padding-left: 15px;
            text-align: left;
            font-weight: 600;
            font-size: 15px;
            border: 1px solid #a5a0a0;
            border-right: 0;

            &:last-child {
                border-right: 1px solid #a5a0a0;
            }
        }
    }

    .uploadedFileContainer {
        & > div {
            padding: 10px 0 10px 15px;
            text-align: left;
            font-size: 14px;
            border: 1px solid #a5a0a0;
            border-right: 0;
            border-top: 0;
            min-height: 42px;

            &:last-child {
                border-right: 1px solid #a5a0a0;
            }
        }

        .fileNameValue {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.mat-standard-chip {
    .mat-chip-remove,
    .mat-chip-trailing-icon {
        .mat-icon {
            height: 18px;
            width: 18px;
            font-size: 18px;
            background: transparent;
        }
    }
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $taxillaDarkGreen;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: $taxillaDarkGreen;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: $taxillaDarkGreen;
}

.ps__rail-y {
    right: 0 !important;
    left: unset !important;
}

.mat-no-suffix {
    &.mat-form-field-appearance-legacy {
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        .mat-form-field-underline {
            bottom: 0;
        }
    }
}

.searchTabRow,
.relationsTab {
    mat-radio-group {
        mat-radio-button {
            font-family: $fontFamily;
            font-size: 13px;
            margin-right: 50px;
            color: #000;
            padding-top: 10px;

            .mat-radio-outer-circle {
                width: 17px;
                height: 17px;
            }

            .mat-radio-label-content {
                padding-left: 15px;
            }

            &.mat-radio-checked {
                .mat-radio-outer-circle {
                    border-width: 6px;
                }
            }
        }
    }
}

.new-query-builder {
    background: rgba(179, 65, 93, 0.1) !important;
    padding: 10px;

    .q-tree {
        padding-left: 0;

        .q-row {
            padding: 10px 15px;
            border: none;
            background: rgba(179, 65, 93, 0.1) !important;

            mat-form-field.mat-form-field-appearance-outline {
                font-family: $fontFamily;
                font-size: 13px;
                font-weight: bold;
                color: #707070 !important;

                .mat-form-field-outline-start,
                .mat-form-field-outline-end {
                    border-radius: 0 !important;
                }

                .mat-form-field-outline {
                    background: #fff;
                }

                .matformfieldheight {
                    height: 40px;
                }

                .mat-form-field-infix {
                    padding: 0.4em 0 0.6em 0;

                    mat-select {
                        font-family: $fontFamily;

                        .mat-select-placeholder,
                        .mat-select-value {
                            color: #707070 !important;
                        }

                        .mat-select-arrow {
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 9px solid;
                            margin-top: 5px;
                            color: $taxillaDarkGreen;
                        }
                    }
                }
            }
        }
    }

    .q-connector::after,
    .q-connector::before {
        border-style: none !important;
    }

    .queryButton {
        font-family: $fontFamily;
        font-size: 13px;
        color: #fff !important;
        margin-right: 5px;

        &.addRule,
        &.addRuleSet {
            background-color: #71b874;
        }

        &.removeRuleSet,
        &.removeRule {
            background-color: #d50000;
        }
    }
}

.analyticsFilterMenu {
    min-width: 350px;
}

.selectedFilterContainer {
    .customSearchContainer {
        .mat-tab-list {
            transform: translateX(0) !important;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1380px) {
    material-textarea {
        textarea {
            overflow: scroll !important;
        }
    }
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
    display: none !important;
}

.cron-editor-select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.7rem center;
    background-size: 1em;

    &.form-control:disabled,
    &.form-control[readonly] {
        background-color: #e9ecef;
        opacity: 1;
    }
}

.mastersReportsDownloadMenu {
    .mastersReportName {
        display: inline-block;
        max-width: calc(100% - 48px);
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.onNewUI {
    .routeIconButton {
        font-size: 35px !important;
        color: #d81844 !important;
        width: 35px !important;
        height: 35px !important;
        line-height: 37px !important;
    }

    .routeIcon {
        font-size: 35px !important;
        color: #d81844 !important;
    }

    .newInvoicesContainer {
        padding-left: 1px;
    }

    /* Transactions / Closures / Returns related CSS START*/
    .groups {
        width: calc(100% - 20px);
        height: calc(100% - 10px);
        margin-left: 10px;
        position: relative;
        border-radius: 4px;
        background: transparent;

        .transactions {
            display: inline-block;
            padding-left: 15px;
            font-size: 18px;
            position: relative;
            font-weight: 300;
            max-width: calc(100% - 40px);
            vertical-align: middle;
            line-height: 55px;
        }

        .groupTitleLayout {
            width: calc(25% - 15px);
            height: 96px;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
            display: inline-block;
            margin: 7.5px;
            padding: 10px;
            cursor: pointer;
            white-space: unset;
            text-overflow: ellipsis;
            overflow-x: hidden;
            overflow-y: hidden;
            word-break: break-all;

            .groupTitleBtn {
                margin: auto;
                display: flex;
                height: 40px;
                width: 100%;
                position: relative;
                justify-content: center;
                top: 20px;

                .groupName {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: 60px;
                    font-family: Lato;
                    font-size: 15px;
                }

                .groupIcon {
                    vertical-align: middle;
                    top: 3px;
                    height: 18px;
                    padding: 0px 10px;
                    position: relative;
                }
            }

            .discription {
                max-width: calc(100% - 15px);
                word-wrap: break-word;
                overflow: hidden;
                max-height: calc(100% - 40px);
                color: #616161;
                font-family: Lato;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                position: relative;
                margin-left: 15px;
                display: block;
                word-break: break-word;
                white-space: normal;
                text-overflow: ellipsis;
            }

            &:hover {
                border-radius: 4px;
                background: var(--Green, #00a651);
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
                .groupName {
                    color: var(--White, #fff);
                }
                .discription {
                    color: var(--White, #fff);
                }
            }
        }

        .noTransactionsFoundContainer {
            width: 100%;
            height: 100%;
            position: relative;

            .noTransactionsFound {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }

    .menuClass {
        border-top: 2px solid #00a651;
    }

    .menuButton .mat-button-ripple .mat-ripple-element {
        background: currentColor !important;
    }

    /* Transactions / Closures / Returns related CSS END*/
}

app-common-templates {
    width: 100%;
}

// Angular rich text editor CSS
.angular-editor {
    margin: 12px 0 0 0;
}
.angular-editor-toolbar {
    min-height: 38px;
}
.angular-editor-textarea {
    min-height: 0 !important;

    &.plain-text {
        overflow: unset;
        resize: unset;
    }

    ol,
    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
    ol {
        list-style-type: decimal;
    }

    a {
        color: -webkit-link;
        cursor: text;
        text-decoration: underline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        display: block;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
    }
    h1 {
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
    }
    h2 {
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
    }
    h3 {
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    h4 {
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
    }
    h5 {
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
    }
    h6 {
        font-size: 0.67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
    }
}

app-rich-text-editor mat-hint {
    font-size: 75%;
}

ae-toolbar-set ae-button.showHideActions.active button {
    background: #fff5b9 !important;
}
