@import '/src/app/modules/taxilla-library/lib/styles/recordfields.scss';

.enReportMainContainer {
    .non-context-class {
        position: fixed;
        background: rgba(128, 128, 128, 0.4);
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-left: 0;
        top: 44px;
        right: 0;
        padding: 44px 0 0 44px;
    }

    #preLoginContainer,
    #profileHeaderContainer,
    #mainRouterContainer.device-handset.app-responsive,
    .app-responsive .ForgotpasswordPage {
        height: 100%;
    }

    .mat-icon-button:not(.queryButton) {
        color: $matIconGreyColor;

        &.mat-button-disabled {
            color: $lightGreyColor;
        }
    }

    .mat-menu-item,
    .mat-paginator,
    .mat-paginator-page-size .mat-select-trigger,
    .mat-radio-button,
    .mat-select,
    .mat-slide-toggle-content,
    .mat-slider-thumb-label-text,
    .mat-tab-group,
    .mat-tab-label,
    .mat-tab-link,
    .mat-tooltip,
    .mat-list-item,
    .mat-list-option,
    .mat-list-base .mat-subheader,
    .mat-list-base[dense] .mat-subheader,
    .mat-option,
    .mat-simple-snackbar,
    .mat-simple-snackbar-action,
    .mat-tree,
    .mat-badge-content,
    .mat-button,
    .mat-fab,
    .mat-flat-button,
    .mat-icon-button,
    .mat-mini-fab,
    .mat-raised-button,
    .mat-stroked-button,
    .mat-button-toggle,
    .mat-card,
    .mat-checkbox,
    .mat-table,
    .mat-calendar,
    .mat-expansion-panel-header,
    .mat-form-field,
    .mat-stepper-horizontal,
    .mat-stepper-vertical {
        font-family: $fontFamily;
    }

    .mat-fab.mat-primary,
    .mat-flat-button.mat-primary,
    .mat-mini-fab.mat-primary,
    .mat-raised-button.mat-primary {
        background-color: $taxillaDarkGreen;
    }

    .mat-badge-content {
        background-color: orange;
    }

    .mat-form-field-infix input[matInput] {
        font: inherit;
        background: 0 0;
        color: currentColor;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
        vertical-align: bottom;
        text-align: inherit;
    }

    .mat-form-field-infix input[matInput],
    .mat-form-field-infix input[matInput]::-webkit-search-cancel-button,
    .mat-form-field-infix input[matInput]::-webkit-search-decoration,
    .mat-form-field-infix input[matInput]::-webkit-search-results-button,
    .mat-form-field-infix input[matInput]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    .mat-form-field-infix input {
        border: 0;
        width: 100%;
    }

    .mat-form-field-infix {
        width: 110px !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        // color: #817f7f;
        color: $placeholderHintColor;
    }

    mat-form-field {
        width: 100%;
    }

    // .mat-form-field-infix {
    //     padding: 0.4375em 0;
    //     border-top: 1.5em solid transparent;
    // }
    // if issue raised from qa related this, please have a container class and target this class

    .mat-form-field-infix input::-ms-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::-webkit-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input:-ms-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::-moz-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::placeholder {
        color: transparent;
    }

    textarea.mat-input-element.cdk-textarea-autosize {
        resize: auto !important;
        white-space: nowrap;
    }

    .mat-form-field {
        textarea {
            height: 19px;
        }
    }

    .mat-menu-content:not(:empty) {
        padding: 0 !important;
        max-height: none !important;
    }

    input::-webkit-input-placeholder,
    select::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: transparent;
    }

    input::-moz-placeholder,
    select::-moz-placeholder,
    textarea::-moz-placeholder {
        color: transparent;
    }

    input:-ms-input-placeholder,
    select:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        color: transparent;
    }

    input::-ms-input-placeholder,
    select::-ms-input-placeholder,
    textarea::-ms-input-placeholder {
        color: transparent;
    }

    input::placeholder,
    select::placeholder,
    textarea::placeholder {
        color: transparent;
    }

    textarea {
        width: 100%;
        height: 14px;
        /*TAF-2213 Height of textarea is disturbing the UI. We might revert this based on user feedback*/
    }

    .mat-form-field-label {
        font-weight: 400 !important;
    }

    input:checked + .slider {
        background-color: #32ac7b;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #32ac7b;
    }

    input:checked + .slider:before {
        transform: translateX(38px);
    }

    input.require::-webkit-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.5rem;
    }

    .mat-form-field-hide-placeholder .mat-select-placeholder {
        color: transparent;
        -webkit-text-fill-color: transparent;
        transition: none;
        display: block;
    }

    .mat-form-field input::-webkit-input-placeholder {
        font-size: 14px;
        text-shadow: none;
        color: transparent;
    }

    .mat-form-field input:-ms-input-placeholder {
        font-size: 14px;
        text-shadow: none;
        color: transparent;
    }

    .mat-form-field input::-ms-input-placeholder {
        font-size: 14px;
        text-shadow: none;
        color: transparent;
    }

    .mat-form-field input::-moz-placeholder {
        font-size: 14px;
        text-shadow: none;
        color: transparent;
    }

    .mat-form-field input::placeholder {
        font-size: 14px;
        text-shadow: none;
        color: transparent;
    }

    .mat-form-field-label-wrapper {
        position: absolute;
        left: 0;
        box-sizing: content-box;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
    }

    .mat-form-field:not(.mat-focused) .mat-form-field-label {
        /* font-weight: 400 !important;
font-size: 14px !important;
top: 1.8em !important; */
        text-shadow: none !important;
        color: $placeholderHintColor !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: $lineColor;
    }

    label {
        margin-bottom: 0;
    }

    input[disabled],
    .mat-select-disabled .mat-select-trigger {
        cursor: not-allowed !important;
        color: rgba(0, 0, 0, 0.38) !important;
    }

    .mat-form-field-appearance-legacy {
        input:-webkit-autofill {
            background: none;
        }

        input:-webkit-autofill + span label {
            transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
            width: 133.33333% !important;
        }
    }

    tr.mat-header-row {
        height: 59px !important;
        background: #f1f1f1 !important;
    }

    /* .pAdjust td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
padding: 0 1em !important;
} */

    .mat-header-cell {
        color: rgba(0, 0, 0, 0.77);
        font-size: 14px;
        font-weight: 600;
    }

    .material-icons-outlined {
        color: grey;
    }

    .mat-checkbox-checked .mat-checkbox-background,
    .mat-checkbox-indeterminate .mat-checkbox-background,
    .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element,
    .mat-radio-button .mat-radio-inner-circle,
    .mat-radio-button .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    .mat-radio-button.mat-radio-checked .mat-radio-persistent-ripple,
    .mat-radio-button:active .mat-radio-persistent-ripple {
        background-color: $taxillaDarkGreen !important;
    }

    .mat-accent .mat-pseudo-checkbox-checked,
    .mat-accent .mat-pseudo-checkbox-indeterminate,
    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
        background: $taxillaDarkGreen !important;
    }

    .noReportRecord {
        color: rgba(0, 0, 0, 0.87);

        .mat-pseudo-checkbox-disabled {
            display: none !important;
        }
    }

    .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
        border-color: $taxillaDarkGreen !important;
    }

    .mat-focused .mat-form-field-label {
        color: $taxillaDarkGreen !important;
    }

    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: $taxillaDarkGreen !important;
    }

    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
        background-color: #f44336;
    }

    .routerOutlet {
        // height: calc(100% - #{$headerHeight + $footerHeight});
        height: calc(100% - #{$headerHeight});
    }

    td.expandedTableRow.mat-cell {
        border-bottom-width: 1px;
        border-bottom-color: #d8d8d8;
    }

    .mat-button.mat-black {
        background: #272727;
        color: #fff;
    }

    .navbar-nav {
        flex-direction: unset !important;
    }

    button {
        &.mat-button {
            &.mat-menu-trigger[disabled='true'] {
                border-bottom-style: dashed;
            }
        }
    }

    .hide {
        display: none;
    }

    .packagePageHeaderContainer {
        height: 48px;
        font-size: 13px;

        .packagePageBreadcrumbContainer {
            padding: 15px 15px 15px 0;
            height: 48px;
            margin-left: 15px;
            flex: auto;

            .packageDetailBreadcrumbText {
                cursor: pointer;
                text-transform: capitalize;
                display: inline-block;
                vertical-align: middle;
                color: $taxillaDarkGreen !important;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .packageDetailBreadcrumbText:hover {
                color: $taxillaDarkGreen;
                // font-weight: bold;
            }

            .packageDetailBreadcrumbItem::first-letter {
                text-transform: uppercase;
            }

            .packageDetailBreadcrumbItem {
                display: inline-block;
                margin: 0 15px 0 0;

                span.packageDetailBreadcrumbNext {
                    margin: 0 0;
                    vertical-align: middle;
                    display: inline-block;
                }

                .fontSizeCls {
                    font-size: 12px;
                    position: relative;
                    top: 6px;
                    left: 3px;
                    width: 20px;
                }

                #breadCrumbIcon {
                    top: 9px !important;
                }

                .packageDetailBreadcrumbItem:first-child {
                    margin-left: 0;
                }
            }
        }

        .packagePageActionsContainer {
            padding: 0 15px 0 0;
            margin: 0 15px 0 0;

            .groupActionsContainer {
                margin: 0;

                .categoryActionItemContainer {
                    line-height: 45px;
                }

                .categoryActionItemContainer.activeIcon {
                    span.material-icons {
                        color: $submitButtonColor;
                    }
                }
            }

            .processUploadActionButton {
                border: 1px solid $submitButtonColor;
                margin: 7px;
                height: 34px;
                font-weight: 600;
                line-height: 34px;
            }

            .cancelProcessActionButton {
                border: 1px solid $submitButtonColor;
                margin: 7px;
                height: 34px;
                font-weight: 600;
                line-height: 34px;
            }
        }

        &.appsPageBreadcrumb {
            padding-left: 15px;
        }
    }

    .packagePageContainer {
        &.onNewUI {
            background: unset;
        }
        background: $bodyBgColor;

        .appsPageRoutingContainer {
            height: calc(100% - 45px);

            app-transmissions-view {
                .transmissionsViewContainer {
                    margin: 0;
                    height: 100%;

                    .processesViewMatCard {
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        flex: 1 0 100%;
                        max-width: 100%;

                        .processesViewContainer {
                            padding: 0;
                        }

                        &.inboxStyle {
                            .processesViewContainer {
                                padding: 0 0px;
                            }
                        }
                    }

                    .searchFocus {
                        margin: 4px;
                    }

                    .processesRowActionsContainer {
                        padding: 0 10px;
                    }
                }

                .processesViewHeader {
                    .inboundleftSideHeader {
                        .processesRefreshInstances {
                            vertical-align: middle;
                            height: 24px;
                            display: inline-block;
                        }

                        .closeTransmissions {
                            display: inline-block;
                            // height: 22px;
                            vertical-align: middle;
                            padding: 1px 0 1px 20px;
                        }
                    }
                }
            }

            app-search-reports {
                .transmissionsViewContainer {
                    margin: 0;
                    height: 100%;

                    .processesViewMatCard {
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        flex: 1 0 100%;
                        max-width: 100%;

                        .processesViewContainer {
                            padding: 0;
                        }

                        &.inboxStyle {
                            .processesViewContainer {
                                padding: 0 0px;
                            }
                        }
                    }

                    .processesRowActionsContainer {
                        padding: 0 10px;
                    }
                }

                .processesViewHeader {
                    .inboundleftSideHeader {
                        .processesRefreshInstances {
                            vertical-align: middle;
                            height: 24px;
                            display: inline-block;
                        }

                        .closeTransmissions {
                            display: inline-block;
                            vertical-align: middle;
                            padding: 1px 0 1px 20px;
                        }
                    }
                }
            }

            .packagePageBreadcrumbContainer {
                width: calc(100% - 720px);
                text-overflow: ellipsis;
                height: 48px;
            }

            .processesViewMatCard {
                margin: 0 1%;
                transition: 0.3s;
                width: 100%;
                flex: 1 0 96%;
                max-width: 100%;
                background: #fff;
            }

            .processesViewMatCard.inboxStyle {
                width: 30% !important;
                flex: 0 0 30%;
                max-width: 30% !important;
                margin: 0 0.5% 0 2%;
                padding: 0px;

                .processesViewContainer {
                    .processesViewHeader {
                        margin: 0;
                        height: auto;
                        padding: 0 0 0 7px;
                        border-bottom: 1px solid $curtainBoxShadowColor;

                        .processesViewAppName {
                            width: 50%;
                            cursor: pointer;
                            padding-left: 12px;

                            .processesViewNameText {
                                max-width: calc(100% - 20px);
                                text-overflow: ellipsis;
                                overflow-x: hidden;
                                white-space: nowrap;
                                display: inline-block;
                                vertical-align: middle;
                                line-height: 30px;
                            }
                        }

                        .inboundRightHeader {
                            width: 100%;

                            .inboxStyleFeedActionsContainer {
                                width: 50%;
                            }
                        }

                        .selectAllSpan {
                            padding-left: 2px;
                        }
                    }
                }

                .processesTableContainer,
                .transmissionsMainContainer {
                    padding: 0;
                }

                .cdk-virtual-scroll-orientation-vertical {
                    .cdk-virtual-scroll-content-wrapper {
                        box-shadow: none;
                        border: none;
                        padding-bottom: 40px;
                    }
                }

                .srchIcon {
                    display: none;
                }
            }

            .processesViewDetailContainer {
                padding: 0px;
                margin: 0 0%;
                transition: 0.3s;
                width: 65%;
                flex: 0 0 65%;
                max-width: 0%;
                overflow-x: hidden;
                font-size: 14px;
                background: #fff;
                height: 100%;

                .processesViewDetailHeaderContainer {
                    padding: 0 10px 5px;
                    position: relative;
                    height: 42px;
                    line-height: 34px;
                    border-bottom: 1px solid $headerItemBorderColor;
                    font-size: 15px;

                    .processesViewHeaderActions {
                        position: absolute;
                        right: 5px;

                        .closeColor {
                            margin: 10px;
                        }
                    }

                    .processesViewAppName {
                        padding: 5px;
                        font-weight: bold;
                        position: relative;
                        top: 3px;
                    }
                }

                .processAttributesDataBlock {
                    padding: 20px 40px 0;

                    .processAttributesHeader {
                        padding: 10px;
                        background: #ececec;
                    }

                    .processAttributesDetails {
                        margin: 0;
                        padding: 0 30px;
                        position: relative;
                        min-height: 150px;

                        .processAttributesDetailsFieldContainer {
                            flex: 1;
                            min-width: 33.33%;
                            margin-top: 10px;
                            max-width: 33.33%;

                            .processAttributeFieldHeader {
                                strong {
                                    display: inline-block;
                                    vertical-align: middle;
                                    line-height: 40px;
                                }

                                .material-icons-outlined {
                                    line-height: 24px;
                                    vertical-align: middle;
                                    display: inline-block;
                                    transform: rotateZ(45deg);
                                    margin-left: 10px;
                                }
                            }
                        }
                    }

                    .precaptureError {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }
                }
            }

            .processesViewDetailContainer.inboxStyle {
                // padding: 5px;
                margin: 0 0 0 10px;
                max-width: 65%;
            }

            &.hiddenBreadcrumb {
                // padding: 10px;
                height: 100%;
            }
        }
    }

    .processesViewContainer {
        .processesDataContainer {
            height: calc(100% - 43px);
        }

        .processesViewHeader {
            margin: 0;
            // padding: 0 10px 0 20px;
            padding: 0 15px;
            border-bottom: 1px solid $headerItemBorderColor;

            .processesViewAppName {
                display: inline-block;
                text-overflow: clip;
                overflow-x: hidden;
                overflow-y: hidden;
                white-space: nowrap;
                font-size: 15px;
                font-weight: bold;
                line-height: 37px;
                vertical-align: middle;
                cursor: pointer;

                .dropdownCaretIconContainer {
                    line-height: 37px;
                    display: inline-block;
                    height: 37px;
                    vertical-align: middle;

                    mat-icon {
                        line-height: 33px;
                        display: inline-block;
                        height: 37px;
                        vertical-align: middle;
                    }
                }
            }

            .processesViewHeaderActions {
                .processCountShowingContainer {
                    display: inline-block;
                    margin: 0 10px;
                    vertical-align: middle;

                    .showingText {
                        font-weight: 600;
                        margin-right: 5px;
                    }
                }

                .switchToInboundTransmissions {
                    margin: 0 5px;

                    .switchTonewProcesses {
                        margin: 0 5px;
                    }

                    .viewInboundTransmissionsBtn {
                        cursor: pointer;
                        position: relative;
                        top: 0px;
                        left: 0px;

                        img {
                            // background: $taxillaDarkGreen;
                            background: $matIconGreyColor;
                            padding: 4px;
                            max-width: 27px;
                            max-height: 32px;
                            border-radius: 3px;

                            &:hover {
                                background: $taxillaDarkGreen;
                            }
                        }
                    }
                }

                .allProcessesButton {
                    padding-left: 15px;
                    display: inline-block;
                    vertical-align: middle;
                    height: 32px;
                    margin-right: 10px;
                    line-height: 32px;
                    overflow: hidden;
                }

                .processesMultiActions {
                    display: inline-block;
                    line-height: 28px;
                    vertical-align: middle;

                    .processesRefreshInstances {
                        height: 24px;
                        display: inline-block;
                        // margin-right: 10px;
                        vertical-align: middle;
                        line-height: 26px;
                    }

                    .processesCloseInstances {
                        position: relative;
                        line-height: 24px;
                        height: 28px;
                        display: inline-block;
                        vertical-align: middle;
                        top: 0;
                        margin-left: 20px;
                        margin-top: 8px;
                    }
                }
            }

            .reportNewProcessButton {
                padding-left: 0px !important;

                button {
                    width: 100px !important;
                    text-align: center;
                    margin-right: 5px;
                }
            }

            .mat-button:not(.inSideSearch),
            button:not(.inSideSearch) {
                line-height: 30px;
                height: inherit;
                width: 30px;
                padding: 0px 12px;
            }

            .processesMultiActions {
                .closeColor {
                    margin-left: 5px;
                }
            }
        }

        .processesRowContainerScroll {
            overflow-y: auto !important;
        }

        .processesRowsContainer {
            position: relative;
            width: 100%;
            max-height: 100%;
            overflow: hidden;
            overflow-x: auto;

            .processesRowContainer {
                border-bottom: 1px solid $lineColor;
                // border-left: 4px solid #fff;
                padding-left: 4px;

                .processesRowInfoContainer {
                    width: 100%;
                    display: flex;
                    padding: 5px 0px;

                    .processesRowSelectContainer {
                        // -webkit-box-flex: 1;
                        // flex: auto;
                        width: 15px;
                        position: relative;
                        // max-width: 50px;

                        .mat-checkbox {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                        }
                    }

                    .processesRowDetailsContainer {
                        flex: auto;
                        // display: inline-block;
                        // max-width: calc(100% - 15px);
                        // padding: 5px 0 5px 10px;
                        line-height: 1.4;

                        .processesRowDetailContainer {
                            width: 50%;
                            display: inline-block;
                            max-width: 50%;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            padding: 0 5px;
                        }
                    }
                }

                &:hover {
                    background: $oddItemBgColor;
                }
            }

            .processesRowContainer.entitySelectedRecord {
                padding-left: 0;
                border-left: 4px solid $submitButtonColor;
                background: $oddItemBgColor;

                .recordActionContainer.selectedAction {
                    background: $submitButtonColor;

                    .mat-icon-button {
                        span {
                            color: #fff;
                        }
                    }
                }
            }

            .processContentCntr {
                position: relative;
                overflow: hidden;
                // padding: 5px 5px 0px 0px;

                .processesRowSelectContainer {
                    position: absolute;
                    width: 10%;
                    top: calc(50% - 18px);
                    padding: 5px 15px;
                    float: left;
                }

                .processDataColCntr {
                    width: 90%;
                    float: right;
                    // padding-left: 3px;
                }
            }

            .processesRowActionsContainer {
                // display: -webkit-box;
                // display: -ms-flexbox;
                // display: flex;
                margin-bottom: 5px;
                text-align: left;
                padding: 0px 10px;

                .recordActionContainer {
                    // -webkit-box-flex: 1;
                    // -ms-flex-positive: 1;
                    // flex-grow: 1;
                    text-align: center;
                    display: inline-block;
                    padding: 1px 7px 2px;
                    border-radius: 5px;

                    &:hover {
                        background: $lineColor;
                    }

                    .mat-icon-button {
                        width: 15px;
                        height: 15px;
                        line-height: 15px;

                        span {
                            line-height: 15px;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    app-process-view,
    app-inbound-transmissions-view,
    app-inbound-transmissions,
    app-processes,
    app-apps-page {
        position: relative;
        display: block;
    }

    .recordTable {
        height: calc(100vh - 160px);
    }

    .recordTableWithSearch {
        height: calc(100vh - 160px - 60px) !important;
    }

    .fieldCheckboxLabelIcon {
        margin-left: 15px;
        vertical-align: middle;
    }

    .successPanelClass {
        background: $submitButtonColor;
        color: white;
        text-align: center !important;
    }

    .failurePanelClass {
        background: #e6140eb8;
        color: white;
        text-align: center !important;
    }

    .warningPanelClass {
        background: $taxillaOrangeColor;
        color: white;
        text-align: center !important;
    }

    .notifyPanelClass {
        background: #f9eacf;
        color: black;
        text-align: center !important;
    }

    .successPanelClass .mat-simple-snackbar,
    .failurePanelClass .mat-simple-snackbar,
    .notifyPanelClass .mat-simple-snackbar,
    .warningPanelClass .mat-simple-snackbar {
        justify-content: center;
    }

    .inboundTransmisionTable {
        width: 100%;

        .mat-elevation-z8 {
            box-shadow: unset !important;
        }
    }

    .fieldCheckboxLabel {
        display: inline-block;
        vertical-align: middle;
    }

    .hiddenContainer {
        display: none;
    }

    .hiddenContainer.show {
        display: inherit;
    }

    .inboundleftSideHeader {
        // display: -webkit-box;
        // display: -ms-flexbox;
        // display: flex;
        // float: right;

        .inboundRefresh {
            margin-right: 30px;

            .refreshButton {
                top: -3px;
            }
        }

        .inboundSearch {
            float: right;
            font-size: 14px !important;
            margin-right: 15px;

            .search-form-field {
                .mat-form-field-infix {
                    // padding: unset !important;
                    border-top: unset !important;
                }

                // .mat-form-field-wrapper {
                //     padding-bottom: unset !important;
                //     margin: 0 !important;
                // }
            }

            .searchIcon {
                color: $taxillaDarkGreen;
            }

            .filterIcon {
                display: inline !important;
            }

            .filterDiv {
                display: flex;
                align-items: center;
            }
        }

        .inboundCountDiv {
            display: flex;
            margin-left: 10px;

            .showingText {
                font-weight: 600;
                margin-right: 5px;
            }

            .countSelectBox {
                .mat-form-field-infix {
                    border-top: unset !important;
                }
            }
        }
    }

    .showingCountContainer {
        border: 1px solid $matTableRowBorderGrey;
        padding: 5px 22px 7px;
        border-radius: 4px;

        span {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
        }

        .currentCount {
            width: auto;
            // min-width: 25px;
            text-align: center;
            // background: #d0d0d0;
        }
    }

    // .inboundRightHeader {
    //     display: inline;
    // }

    th.matTableHeader.mat-header-cell.mat-column-log {
        // padding-left: 0px !important;
        // padding-right: 0px !important;
        width: 18px !important;
    }

    td.mat-cell.mat-column-log {
        padding: 0 !important;
    }

    td.matTableMessages {
        text-align: center;

        &.mat-column-loading {
            padding: 0 !important;

            .loaderBlock.isLoading {
                width: 300px;
                text-align: center;
                margin: 9px;
                height: 10px;
            }
        }
    }

    .tableActions {
        position: relative;
        padding: 0 0 1em 0;
    }

    .logMenu {
        min-width: 260px;
        max-width: 300px;
        width: 260px;
        max-height: 350px;
        padding: 1em;
        overflow-x: hidden;
        /* overflow: auto; */
        /* top: 0; */
        /* left: 31em; */
    }

    .actionCheckBox {
        margin: 1em 0 0 0;
    }

    .actionItem {
        margin: 1em 0 0 1em;
    }

    .tableActions button {
        margin: 0 1em 0 0;
    }

    .logListItems {
        transition: all 0.3s ease-in;
    }

    .checkStyle label {
        font-weight: unset;
    }

    .nextButton {
        width: 100%;
        background: $taxillaDarkGreen;
        color: white;
    }

    .cancelButton {
        width: 100%;
    }

    .cancelButtonDiv {
        margin-right: 10px;
    }

    .logIcon {
        cursor: pointer;
    }

    .actionsMenu {
        .actionButtons {
            .matTableActionIcon {
                font-size: 18px;
            }

            &:hover {
                background: #ebebeb;
                border-radius: 5px;
            }
        }
    }

    .actionIcon {
        .actionButtons:last-child {
            background: #32ac7b;

            .matTableActionIcon {
                color: #fff !important;
            }
        }
    }

    .tableColumnAccordian {
        .mat-expansion-panel:not([class*='mat-elevation-z']) {
            box-shadow: unset;
        }

        .title_icon {
            color: $taxillaDarkGreen;
        }

        .mat-expansion-panel-body {
            padding: 0 0px 15px !important;
        }

        .accordian_body {
            display: flex;

            .accordian_body_icon {
                margin-right: 5px;
                font-size: 20px;
            }
        }

        .mat-expansion-panel:hover {
            background-color: #f5f5f5;
        }

        .mat-expansion-panel-header {
            padding: 0;
        }
    }

    .checkSumFlag {
        text-align: center;
        margin: 45px;
    }

    .requestLevel_errors {
        margin: 45px;
        overflow-y: auto;
        height: 550px;
    }

    .contentErrors {
        color: #ff0000;
    }

    .noProcessesDownloadFile {
        position: absolute;
        top: 50%;
        left: 65%;
        transform: translateX(-50%) translateY(-50%);
    }

    .emptyuploadFileDiv {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .dwnloadUploadFile {
        cursor: pointer;
        color: #337ab7 !important;
        text-decoration: underline !important;
    }

    .displayActionColor {
        color: #ff0000;
    }

    .checkSumCopy {
        max-height: 18px;
        cursor: pointer;
        margin-left: 40px;
    }

    // New request starts

    #newRequestComponent .mat-tab-label.mat-tab-disabled,
    #newRequestComponent .mat-tab-link.mat-tab-disabled {
        color: inherit;
    }

    .preCaptureAttributesContainer {
        min-height: 250px;
        margin-bottom: 30px;
        font-size: 14px;
        padding: 0 30px;
    }

    .noPreCaptureAttributes {
        font-size: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .filingAttributeSubmitButn {
        text-align: right;
        margin-top: 25px;
        padding: 0 20px 0 25px;
    }

    .formFieldContainer {
        margin: 5px 0;
        font-size: 14px;
    }

    .trasformationListDiv {
        width: 25%;
        margin: 20px 30px;
        font-size: 14px;
    }

    .transformationTableDiv {
        padding: 10px 30px;

        .mat-row:hover {
            background-color: transparent !important;
        }

        &.assettoasset {
            padding: 0;
        }

        source-table material-table tr.mat-row {
            td.matTableCell.mat-cell {
                padding-top: 5px;
                overflow-x: unset !important;

                &:first-child {
                    overflow-x: hidden !important;
                }
            }
        }
    }

    .organizationsListContainer .tenantsList button {
        width: calc(100% - 40px);
        display: inline-block;
    }

    .organizationSelect {
        margin-right: 10px;
    }

    .reviewMainContainer {
        margin-top: 10px;
        font-size: 14px;
        // min-height: 400px;
        min-height: 280px;
        padding: 0 25px;
    }

    .reviewPreCaptureAttrContainer {
        margin-bottom: 20px;

        .requestReviewPreCaptureContainer {
            padding-left: 20px;
        }
    }

    .requestReviewDataContainer {
        margin-bottom: 20px;

        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .reviewFormHeader,
    .reviewPreCaptureHeader {
        font-weight: 600;
        margin-bottom: 5px;
        // text-decoration: underline;
        background: #ebebeb;
        padding: 10px;
        border-radius: 5px;
    }

    .requestReviewFieldLabel {
        /* min-width: 250px; */
        display: inline-block;
        color: #a1a1a1;
    }

    .requestReviewFieldContainer {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 14px;
    }

    #newRequestComponent {
        margin: 0 1%;
        transition: 0.3s;
        width: 98%;
        flex: 0 0 98%;
        max-width: 98%;
        // padding: 0px 20px;
        background: white;
        height: calc(100% - 10px);

        .materialStepperDiv {
            height: 100%;
        }

        .mat-stepper-horizontal {
            display: block;
            height: 100%;
        }

        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
            padding: 15px 24px 20px;

            .mat-step-label {
                padding: 5px 0 0 0;
            }
        }

        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after,
        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
            top: 26px;
        }

        .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
            top: 26px;
        }

        .mat-step-header.cdk-keyboard-focused,
        .mat-step-header.cdk-program-focused,
        .mat-step-header:hover {
            background: none;
        }

        .mat-tab-label.mat-tab-label-active {
            color: #000 !important;
            opacity: 1;
            font-weight: bold;
        }

        .mat-tab-label:hover {
            color: #000 !important;
            opacity: 1;
            font-weight: bold;
        }

        .mat-horizontal-stepper-header-container {
            padding: 50px 15% 25px;
        }

        .electronicForm {
            padding: 25px 25px 15px;
        }
    }

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done {
        background-color: #00a84d;
        color: #fff;
    }

    .mat-step-header .mat-step-icon-state-edit {
        background-color: rgba(0, 0, 0, 0.54);
        color: $cancelButtonBgColor;
    }

    .mat-horizontal-stepper-header {
        pointer-events: none !important;
    }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: #4ba94e;
    }

    .mat-step-header:hover {
        background-color: transparent;
    }

    .nextBtnCls {
        margin: 0px 15px !important;
    }

    .noTrasformationSelectedCls {
        text-align: center;
        // min-height: 115px;
        min-height: 150px;
        position: relative;
    }

    .noTrasformationSelectedCls .renderContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        font-size: 14px;
    }

    .tableFilesUploadContainer {
        display: inline-block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-align: center;
    }

    .downloadLink {
        color: $submitButtonColor;
        cursor: pointer;

        a {
            color: $submitButtonColor;
            cursor: pointer;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    // .mat-tab-group {
    //     min-height: 250px;
    // }

    .mat-tab-body-content {
        overflow: hidden !important;
    }

    .newRequestComponentHeader {
        padding: 10px 15px;
        border-bottom: 1px solid $headerItemBorderColor;
        font-size: 15px;

        .createNewProcessSpan {
            font-weight: bold;
        }
    }

    .newRequestComponentBody {
        .mat-tab-label {
            font-weight: bold;
        }
    }

    .reportStepperDiv {
        .mat-horizontal-stepper-header-container {
            padding: 0 20% !important;
        }
    }

    .invoiceUploadButton {
        background: gray;
        padding: 8px 18px;
        color: #fff;
        cursor: pointer;
        border-radius: 5px;
    }

    .noOutline {
        border: none !important;
    }

    .processesMultiActions {
        // .refreshInstanceButton {
        //     background: #00a651;
        //     color: #fff;
        // }

        // .cancelAllProcessesButton {
        //     background: #00a651;
        //     color: #fff;
        // }

        .primaryBorderButton {
            border: 1px solid $submitButtonColor;
        }

        // .cancelAllProcessesDiv {
        //     margin: 20px;
        // }

        .refreshInstanceButton {
            // .mat-icon {
            //     font-size: 18px;
            //     margin-top: 3px;
            // }
            cursor: pointer;
        }

        .cancelAllProcessesButton {
            // margin: 0 15px 0 0;
            padding: 0px !important;
            margin: 0px !important;
        }
    }

    cdk-global-overlay-wrapper,
    .cdk-overlay-container {
        z-index: 1051 !important;
    }

    .uploadFileDataComponent {
        width: 60%;
    }

    .uploadFileProcessesContent {
        // min-height: 360px;
        padding-left: 5px;

        .uploadFileProcessesBody {
            margin-top: 30px;

            .mat-row:hover {
                background-color: transparent !important;
            }
        }

        .uploadTransformationsDiv {
            align-items: center;
        }

        .transformationLabelDiv {
            font-weight: 600;
            // padding-left: 15px;
        }

        .uploadTransformationsList {
            align-items: center;
        }

        .uploadTransformationsOptions {
            width: 34%;
            margin-top: 5px;
            // padding-left: 15px;
        }

        .uploadFileLabel {
            font-weight: 600;
        }
    }

    .tableFilesName {
        padding-left: 5px;
        max-width: 140px;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }

    .reportsPage {
        perfect-scrollbar {
            .ps-content {
                height: 100%;

                .matTableContainer {
                    height: 96%;
                }
            }
        }

        .newProcessBtnDiv {
            padding-left: 15px;
            margin: 6px 0;
        }

        .inboundTransmisionTable {
            padding: 15px;
            height: 100%;
        }
    }

    .processesViewDetailBodyContainer {
        height: calc(100% - 42px);
        padding: 15px;
        overflow-y: auto;

        .integrationsStatusMainContainer {
            position: relative;
            width: 100%;
            height: 100%;

            .integrationMainDiv {
                width: 250px;
                height: 150px;
                position: absolute;
                top: 50%;
                left: 50%;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                border-radius: 6px;
                overflow: hidden;
                transform: translateX(-50%) translateY(-50%);

                .integrationHeader {
                    font-weight: bold;
                    padding: 10px;
                    color: #fff;
                    font-size: 15px;
                    text-align: center;
                    background: #828387;
                }

                .integrationContainer {
                    text-align: center;
                    padding: 20px;
                    font-size: 14px;

                    .retryButton {
                        margin-top: 10px;
                        display: inline-block;
                        margin-left: 10px;

                        // .mat-raised-button {
                        //     max-width: 100px !important;
                        // }
                    }
                }
            }
        }

        .indexStatusMainContainer {
            position: relative;
            width: 100%;
            height: 100%;

            .indexDataMainDiv {
                width: 250px;
                height: 150px;
                position: absolute;
                top: 50%;
                left: 50%;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                border-radius: 6px;
                overflow: hidden;
                transform: translateX(-50%) translateY(-50%);

                .indexStatusHeader {
                    font-weight: bold;
                    padding: 10px;
                    color: #fff;
                    font-size: 15px;
                    text-align: center;
                    background: #828387;
                }

                .indexStatusContainer {
                    text-align: center;
                    padding: 38px;
                    font-size: 14px;
                }

                .indexStatusMessage {
                    font-weight: 600;
                }
            }

            &.requestStateContainer {
                .indexDataMainDiv {
                    height: auto;
                    width: auto;
                    min-width: 278px;

                    .indexStatusContainer {
                        padding: 0 25px;

                        .requestStatusContainer {
                            margin: 25px 0;

                            .requestStatus {
                                font-weight: 600;
                            }

                            span.d-block {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        @include collaboratorsListCSS;
    }

    .outlookSearchContainer {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
        text-align: left;
        z-index: 2;
        background: white;

        input {
            margin-top: 0px;
            line-height: 16px;
        }
    }

    .outLookSearchHolder {
        background: white;
        transition: all 0.7s ease 0s;
    }

    .mainSearchSearcher {
        position: relative;
        padding: 0 0.5em;
        height: 32px;
        min-width: 295px;
        transition: all 300ms ease;

        .searchIcon {
            font-size: 19px;
            color: $matIconGreyColor;
        }

        .searchModeIcon {
            font-size: 19px;
            padding-right: 5px;
            margin-top: 5px;
        }

        .searchModeBlock {
            display: contents;
        }

        .searchModeClearIcon {
            padding: 3px;
        }

        .searchMode {
            color: $lightRedColor;
        }

        .searchModeText {
            color: $matIconGreyColor;
            white-space: nowrap;
        }

        .clearSearchMode {
            position: absolute;
            right: 52px;
            top: 4px;
        }

        &.searchModeOn:not(.basicSearch) {
            .searchIcon {
                display: none;
            }

            input {
                display: none;
            }
        }

        .mat-form-field-underline {
            display: none;
        }
    }

    .googleMapsShadow {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
    }

    .realTimeRenderer {
        position: absolute;
        top: 2.9em;
        right: 22px;
        z-index: 1;
        background: white;
        padding: 1em;
    }

    .searchBorder {
        border: 1px solid lightgray;
    }

    .mainSearchPrefix span.material-icons {
        padding: 0.5em;
        font-size: 1.5em;
    }

    .mainSearch,
    .mainSearchInput input {
        height: 41px;
        padding: 5px 20px;
    }

    .recordFilterSearch {
        top: 46px !important;
        width: 73.6% !important;
    }

    app-processes-view {
        .mainSearchFilters.filterVisible {
            width: 90%;
            height: 80%;
        }
    }

    .mainSearchFilters.filterVisible {
        width: 81.6%;
        height: 83%;
        opacity: 1;
        color: $matIconGreyColor;
        top: 0;
        right: 0;
        z-index: 999;

        .srchHeading {
            position: relative;
            height: 42px;
            padding: 10px 10px 10px 20px;
            border-bottom: 1px solid $headerItemBorderColor;

            .srchHeadingLabel {
                font-size: 15px;
            }
        }

        .mat-radio-checked .mat-radio-label-content {
            font-weight: 600;
        }

        .filterSelection {
            padding-left: 20px;
        }

        .searchFilterContainer label,
        .requestFilterContainer label,
        .defaultFilterContainer label {
            font-weight: 600;
        }

        .mat-tab-label {
            opacity: 1;
            font-weight: bold;
            color: $matIconGreyColor;
        }

        // .mat-tab-label.mat-tab-label-active {
        //     color: $matIconGreyColor;
        // }

        .mat-tab-label.mat-tab-label-active {
            color: #333;
        }

        .mat-tab-label:hover {
            color: #333;
        }

        .actionBtnsSection {
            padding: 10px 20px;
            border-top: 1px solid $headerItemBorderColor;
        }
    }

    .searchFilterOption {
        position: relative;

        .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
            color: $taxillaDarkGreen;
        }

        .searchFilterActions {
            position: absolute;
            top: 12px;
            right: 10px;

            span {
                cursor: pointer;
                color: $matIconGreyColor;
                font-size: 20px;

                &.editSaveFilter:hover {
                    color: #333;
                }

                &.deleteSaveFilter:hover {
                    color: $taxillaRedColor;
                }
            }
        }
    }

    .mainSearchInput,
    .mainSearchFilters {
        font-size: 14px;
        position: absolute;
        z-index: 2;
        background: #fff;
        right: 5px;
        width: 0;
        overflow: hidden;
        top: 5px;
    }

    .qbSection h5 {
        background: #ebebeb;
        padding: 7px;
        border-radius: 5px;
        font-weight: bold;
        font-size: inherit;
    }

    .q-row {
        border: unset !important;
        margin-bottom: 7px;
    }

    .q-row:nth-child(odd) {
        background: #f7f7f7 !important;
        border-radius: 7px;
    }

    .mat-search-query-builder .mat-form-field {
        padding-left: 5px;
        padding-right: 5px;
    }

    .mat-search-query-builder mat-form-field {
        width: 33%;
    }

    // outlookSearch
    .assetToAssetInBridgeCls {
        padding: 10px 25px;
        font-size: 14px;
    }

    .processesUploadDataDiv {
        margin-top: 30px;
    }

    .modal-backdrop {
        background-color: #333333;
    }

    .noChangeLogDiv {
        height: 280px;
    }

    .mat-expansion-panel-header {
        padding: 0 10px;
    }

    #reportsTemplateBodyContainer {
        padding: 0 15px;
        margin: 14px 0 10px;
        position: relative;
        height: 100%;

        .mat-expansion-panel-header {
            padding: 0 10px;
            height: 50px !important;
            max-height: 50px;
            box-shadow: 0px 1px 4px #b1b1b1;

            &.mat-expanded {
                font-weight: 600;
                background: rgba(0, 0, 0, 0.02);
            }
        }

        .materialAccordionBody {
            .reportOrganizationContainer {
                .reportChainContainer {
                    margin: 25px 0 25px;

                    .reportChainLabel {
                        display: inline-block;
                    }

                    .reportChainNameContainer {
                        font-weight: bold;
                        line-height: 25px;
                        padding: 10px;
                        background: $bodyBgColor;
                        position: relative;

                        .reportRegenerateIcon {
                            background: $submitButtonColor;
                            color: #fff;
                            width: 94px;
                            text-align: center;
                            border-radius: 4px;
                            line-height: 33px;
                            height: 32px;
                            font-size: 11px;
                            position: absolute;
                            right: 5px;
                            top: 6px;
                            cursor: pointer;

                            mat-icon {
                                vertical-align: middle;
                                color: #fff;
                            }
                        }
                    }

                    .reportTransformationsContainer {
                        position: relative;
                        display: block;
                        white-space: normal;
                        word-break: break-all;
                        overflow: auto;
                        margin-top: 5px;

                        .reportTransformationContainer {
                            width: calc(100% - 10px);
                            padding: 10px 0 10px 10px;
                            position: relative;
                            float: left;
                            background: #eeffee;
                            margin: 5px;

                            .reportTransformationNameContainer {
                                padding-bottom: 5px;
                                max-width: 100%;
                                overflow-x: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                                .reportTransformationNameLabel {
                                    display: inline-block;
                                    margin-right: 10px;
                                }
                            }

                            .transformationReportMetaDataListContainer {
                                padding-left: 0%;
                                padding-top: 5px;

                                .reportTransformationNameLabel {
                                    display: inline-block;
                                    margin-right: 10px;
                                }

                                .reportsListContainer {
                                    margin-left: 0px;

                                    .reportDataListContainer {
                                        .reportsContainer {
                                            width: 100%;
                                            display: flex;
                                            flex-flow: wrap;
                                        }

                                        .reportsError {
                                            width: 100%;
                                            display: flex;
                                            flex-flow: wrap;
                                            justify-content: center;
                                            color: $taxillaRedColor;
                                            font-size: 12px;
                                        }

                                        .reportDataContainer {
                                            width: 100%;
                                            margin: 10px 0;
                                            display: block;

                                            .reportFileIconContainer {
                                                height: 24px;
                                                display: inline-block;
                                                width: 24px;
                                                line-height: 22px;
                                                margin-right: 5px;
                                                vertical-align: middle;

                                                .mat-icon {
                                                    height: 24px;
                                                    width: 24px;
                                                    font-size: 20px;
                                                }

                                                span.fa {
                                                    font-size: 16px;
                                                    width: 24px;
                                                    text-align: center;
                                                }
                                            }

                                            .reportNameContainer {
                                                width: calc(100% - 118px);
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                height: 24px;
                                                vertical-align: middle;
                                                display: inline-block;
                                            }

                                            .reportRegenerateIcon,
                                            .reportPrintContainer {
                                                height: 24px;
                                                vertical-align: middle;
                                                display: inline-block;
                                                width: 24px;
                                                line-height: 30px;
                                                margin-right: 5px;

                                                mat-icon {
                                                    cursor: pointer;
                                                }
                                            }

                                            .reportDownloadLinkContainer {
                                                height: 24px;
                                                vertical-align: middle;
                                                display: inline-block;
                                                width: 24px;
                                                line-height: 30px;
                                                //margin-right: 5px;
                                            }

                                            .reportDownloadLinkContainer.notGenerated {
                                                color: #b93535;
                                                line-height: 24px;
                                                width: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .noReportsFound {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    icon {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        display: inline-block;

        &.active {
            .material-icons-outlined {
                color: $submitButtonColor;
            }
        }
    }

    .inboxStyle {
        font-size: 13px;

        .inboxStyleFeedActionsContainer {
            display: flex;

            .inboxIconsContainer {
                display: inline-block;
                height: 40px;
                vertical-align: middle;
                flex: auto;
                padding: 8px;

                mat-icon {
                    cursor: pointer;
                }
            }

            .actionsListContainer {
                display: inline-block;
                vertical-align: middle;
                height: 40px;
                width: 40px;
                flex: auto;
            }
        }
    }

    .mat-button,
    .mat-fab,
    .mat-flat-button,
    .mat-icon-button,
    .mat-mini-fab,
    .mat-raised-button,
    .mat-stroked-button,
    .mat-cell,
    .mat-footer-cell {
        font-size: 14px;
    }

    .vAlignSuper {
        vertical-align: super !important;
    }

    .closeOutlookSearch span.material-icons {
        font-size: 16px;
        background: $matIconGreyColor !important;
        color: white;
        border-radius: 50%;
        padding: 2px;
        margin: 5px;
    }

    .closeOutlookSearch {
        position: absolute;
        right: 10px;
        top: 5px;
    }

    .workflowStages {
        min-width: 364px;
        max-width: 485px;
        width: auto;
        position: relative;

        .workflow {
            .workflowText {
                border: 1px solid $matIconGreyColor;
                padding: 5px 8px 4px 8px;
                background: $matIconGreyColor;
                color: white;
                white-space: nowrap;

                // &:after {
                //     content: "";
                //     position: absolute;
                //     height: inherit;
                //     width: inherit;
                //     z-index: 0;
                //     top: 0.5px;
                //     left: 68px;
                //     border: 14.5px solid transparent;
                //     border-left: 14.5px solid #00a651;
                //     border-right: 0;
                // }
            }

            .workflowStageShow {
                border-top: 1px solid $matIconGreyColor;
                border-bottom: 1px solid $matIconGreyColor;
                padding: 2px 1px 1px 1px;
                cursor: pointer;
                min-width: 240px;
                background: #fff;

                .workflowStage {
                    margin: 0 auto;

                    .actualStage {
                        span.material-icons {
                            font-size: 25px;
                            width: 20px;

                            &:first-child {
                                margin-left: -3px;
                            }

                            &.cursorNotAllowed {
                                color: #a2a2a2;
                                pointer-events: none;
                            }
                        }

                        .actualText {
                            // color: #ff4c3f;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            min-width: 195px;
                            max-width: 195px;
                            overflow-x: hidden;
                            text-align: center;

                            &.activeWorkflowStage {
                                font-weight: bold;
                                color: $taxillaDarkGreen;
                            }
                        }
                    }
                }
            }
        }
    }

    .auditMode {
        .workflowStages {
            display: none !important;
        }
    }

    .disabledWorkflowActions {
        pointer-events: none;
        cursor: not-allowed !important;
        background: #b1b1b1 !important;
        border: 1px solid #b1b1b1 !important;
    }

    .processDetailActionBtns {
        margin-left: 5px;

        .mat-icon-button {
            width: 27px;
            height: 28px;
            line-height: 27px;
            margin-left: 5px;

            &:hover {
                background: $matTableRowBorderGrey;
                border-radius: 50%;
            }

            &.inboundTransBtn {
                background-color: #616161;
                max-width: 24px;
                max-height: 23px;
                line-height: 24px;
                border-radius: 4px;
                .mat-button-wrapper {
                    line-height: 10px;
                    text-align: center;
                }
                &:hover {
                    background: $taxillaDarkGreen;
                }
            }

            &#showMenuActions {
                .mat-button-wrapper {
                    line-height: 27px;

                    mat-icon {
                        height: 26px;
                    }
                }
            }

            .mat-button-wrapper {
                line-height: 27px;
                display: block;

                .material-icons {
                    line-height: 27px;
                    width: 27px;
                    font-size: 18px;
                    position: relative;
                    top: -1px;
                }
            }
        }

        .inboundTransBtn.mat-icon-button {
            position: relative;
        }
    }

    .closeColor {
        background: #616161 !important;
        width: 22px !important;
        height: 22px !important;
        color: #fff;
        box-shadow: none !important;
        line-height: 22px !important;

        .icon28 {
            font-size: 16px;
            margin-top: -3px;
            width: 16px;
            height: 16px;
            margin-right: 1px;
        }

        .mat-button-wrapper {
            padding: 0 !important;
        }
    }

    .processesCloseInstances .closeColor {
        margin: 0px !important;

        &.actionCloseButton {
            margin: 3px 0px 0px 5px !important;

            .icon28 {
                margin-top: -9px !important;
                margin-right: 6px !important;
            }
        }
    }

    .refreshAuditTrailLst {
        // margin: 8px;
        margin: 0px;
    }

    .accordian_body_text {
        color: #00a651;
        text-decoration: underline;
        cursor: pointer;
        max-width: 150px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .matTableHeader.alignCenter {
        // text-align: center;
        text-indent: 24px;
    }

    .matTableCell.alignCenter {
        // text-align: center;
        text-indent: 15px;
    }

    // workflow actions
    .workflowActionsMenu {
        background: #00a651;
        color: white;
        padding: 1px;
        border: 1px solid #00a651;

        span {
            margin-left: 3px;
        }

        span.material-icons {
            color: white !important;
            width: 22px;
            margin-left: -4px;
        }
    }

    .mat-menu-panel {
        min-height: 48px !important;

        &.messagesMenuMainContainer {
            max-width: 100vh;
            min-width: 20vh;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            height: 40vh;
            width: 800px;

            .errorMenuMessages {
                border-top: 3px solid $taxillaRedColor;
            }

            .warningMenuMessages {
                border-top: 3px solid $taxillaOrangeColor;
            }

            .instanceErrorMessages {
                border-top: 3px solid $taxillaRedColor;
            }

            .mat-menu-content {
                height: 100%;

                .messagesMainContainer {
                    height: 100%;
                    padding: 10px;
                    position: relative;

                    .mat-menu-head {
                        font-size: 16px;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #d6d6d6;
                        margin-right: 35px;

                        .processesCloseInstances {
                            position: absolute;
                            right: 10px;
                            top: 11px;
                        }

                        .messageCopyContainer {
                            position: absolute;
                            right: 40px;
                            cursor: pointer;

                            .mat-icon {
                                font-size: 24px;
                                margin-right: 20px;
                            }
                        }
                    }

                    .mat-menu-body.messagesContainer {
                        height: calc(100% - 50px);
                        overflow-y: auto;
                        padding: 10px 0 0;
                        font-size: 13px;

                        .messageContainer {
                            margin: 10px 0;

                            &:first-child {
                                margin-top: 0;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        &.hasWarnings {
                            height: auto;
                            min-height: 60px;
                            max-height: calc(50% - 38px);
                        }
                    }
                }
            }
        }
    }

    // workflow actions

    .fieldDescInfoIcon {
        font-size: 20px;
    }

    .relatedAppsDiv,
    .relatedTenantsDiv {
        // border-bottom: 1px solid #ccc;
        box-shadow: none;

        .relatedSidebarApps {
            border-bottom: 1px solid $leftNavBorderColor;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            padding: 5.5px 10px;

            &:not(:first-child) {
                border-bottom: 1px solid $leftNavBorderColor;
            }

            span.material-icons {
                font-size: 21px;
                height: 21px;
                width: 25px;
                margin-right: 0px !important;
                display: inline-block;
                text-align: center;
                color: $matIconGreyColor;
            }
        }

        .relatedApps {
            padding-left: 10px;
            border-bottom: 1px solid $leftNavBorderColor;
            padding-right: 8px;
            min-height: 150px;

            .searchTenantsDiv {
                padding: 0 13px 0 17px;
            }
        }
    }

    .mastersDiv {
        .relatedSidebarApps {
            padding: 7px 10px;
        }
    }

    /* .relatedAppsIsSelected {
.relatedSidebarApps {
color: #00a651;

span.material-icons {
color: #00a651 !important;
}

.mat-icon {
color: #00a651 !important;
}
}
}

.relatedTenantIsSelected {
.relatedSidebarApps {
color: #00a651;

span.material-icons {
color: #00a651 !important;
}

.mat-icon {
color: #00a651 !important;
}
}
} */

    .invoiceCollapseHeader {
        // padding: 7px 0px 7px 17px;
        padding: 5px;
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
        max-width: 97%;

        .relatedContentCopy {
            font-size: 15px;
            position: relative;
            top: 2px;
            left: 6px;
        }
    }

    .invoiceCollapseHeader .arrowImage {
        float: right;
        margin: 5px 5px;
    }

    a.invoiceCollapseHeader {
        width: 100%;
        display: block;
        color: inherit;
        text-decoration: none;
    }

    .relatedAppDisplayNameCls {
        margin-right: auto;
        margin-left: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
    }

    .relatedAppsHeaderSpan {
        // margin-left: 12px;
        margin-left: 8px;
        cursor: pointer;
        font-size: 13px;
        display: inline-block;
        color: #565656;
    }

    .invoiceCollapseHeader:hover {
        color: #00a651;
        background: $bodyBgColor;
    }

    .masterDataMainContainer {
        height: 100%;
        font-size: 13px;

        .mastersContainerRow {
            height: 100%;
        }

        .masterItems {
            border-right: 1px solid $headerItemBorderColor;
            padding-top: 10px;

            .masterItemSpan {
                padding-left: 10px;
            }
        }

        .settingsfilterItemContainer {
            padding: 5px 15px 5px 3px;
            cursor: pointer;
            font-size: 14px;
        }

        .settingsfilterItemContainer:hover {
            color: #00a651;

            .material-icons-outlined {
                color: #00a651;
            }
        }

        .selectedMasterCls {
            color: #00a651;

            .material-icons-outlined {
                color: #00a651;
            }
        }

        th.mat-header-cell {
            padding: 0 1em !important;
        }

        .masterData {
            padding: 15px;

            .cdk-virtual-scroll-content-wrapper {
                overflow-x: auto;
            }
        }
    }

    .matTableContainer {
        overflow-x: auto;
        width: 100%;
    }

    .tableContainerWithoutPaginator {
        overflow: auto;
    }

    .noMasterRecordDiv {
        height: 360px;
    }

    .masterSelectedDiv {
        .material-icons {
            color: #00a651 !important;
        }

        .relatedAppsHeaderSpan {
            color: #00a651;
        }
    }

    .recordGroupContainer {
        .groupedFields {
            .groupedFieldsHeader {
                font-weight: 700;
                margin: 10px 0;
                font-size: 14px;
                background-color: #efefef;

                span {
                    padding-bottom: 1px;
                    // border-bottom: 1px solid rgba(0, 0, 0, .87);
                }
            }
        }
    }

    .booleanFieldContainer {
        line-height: 65.75px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .booleanLabel {
            padding-right: 0;
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            max-width: 80px;
        }

        .booleanValues {
            padding: 0;
            display: flex;
            // margin: 0px 10px;
            z-index: 1000;

            label {
                width: 5em;
                max-width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;

                .mat-radio-label-content {
                    max-width: calc(100% - 20px);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow-x: hidden;
                }
            }

            .mat-radio-group {
                display: flex;
            }

            .mat-radio-button {
                flex: auto;
                margin: 0;
            }
        }
    }

    footer {
        padding: 8px 0px 0px 8px;
        background: $cancelButtonBgColor;
        position: fixed;
        bottom: 5px;
        left: 5px;
        z-index: 99999;
        visibility: 'visible';
        height: 30px;
        width: 31px;
        border-radius: 25px;
        margin: 0px 8px;
        transition: width 0.3s;

        .logo {
            padding: 0px 5px 8px 0px;
            height: 25px;
        }

        .content {
            font-size: 11px;
            margin-left: 0px;
        }

        .taxillaLogo {
            display: none;
        }

        &:hover {
            width: 160px;

            & .taxillaLogo {
                display: block;
            }

            & .tLogo {
                display: none;
            }
        }
    }

    .addDataHeader {
        padding-bottom: 15px;
        padding-left: 30px;
        font-weight: 600;
    }

    .sourceTableHeaderClass {
        padding-bottom: 15px;
        font-weight: 600;
    }

    .filteringAttributesDiv,
    .organizationsListContainer {
        margin: 30px 20px;
    }

    .newProcessButton {
        .createNewbtn {
            padding: 12px;
            color: #fff;
            // background: $taxillaDarkGreen;
            background: $matIconGreyColor;
            opacity: 0.5;
            font-weight: 600;

            icon {
                margin-right: 5px;
            }

            .mat-icon {
                color: #fff !important;
            }

            &:hover {
                background: $taxillaDarkGreen;
                opacity: 1;
            }
        }
    }

    .filterAttributesHeaderDiv,
    .orgSelectDivHeader {
        font-weight: bold;
        font-size: 14px;
        background: #ebebeb;
        padding: 7px 7px 7px 15px;
        border-radius: 5px;
    }

    .noActivatedRouteFound {
        position: relative;

        .noRouteFoundContainer {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            a {
                color: $submitButtonColor;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    .packageDetailBreadcrumbItem:last-child {
        .packageDetailBreadcrumbText {
            color: #000 !important;
        }
    }

    .instanceBreadcrumb {
        .packageDetailBreadcrumbItem {
            .packageDetailBreadcrumbText {
                color: $taxillaDarkGreen !important;
                cursor: pointer;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .breadcrumbAttributeValue {
                color: #000 !important;
            }
        }
    }

    .selectedFilterContainer {
        // min-height: 380px;
        // max-height: 386px;
        height: 350px;
        overflow: hidden;
        padding: 1.2em;
        padding-left: 20px;
    }

    .customSearchContainer {
        .searchTabRow {
            margin-left: 0px;
            height: 280px;

            .entities {
                .entity {
                    padding-bottom: 5px;

                    .mat-radio-label-content {
                        max-width: 190px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .subEntity {
                        padding-top: 5px;
                    }
                }
            }
        }
    }

    .processDownloadReport {
        margin: auto;
        height: 304px;
        box-shadow: none;

        .bulkDownloadBtn {
            display: flex;
            align-items: center;
        }

        .transformationsContainer {
            position: relative;
            transform: translate(-50%, -50%);
            left: 60%;
            top: 50%;
        }

        .bulkReportCheckBoxCls {
            cursor: pointer;
        }
    }

    .inboxIconsContainer {
        .addButton {
            icon {
                background-color: $taxillaDarkGreen;
                border-radius: 12px;

                mat-icon {
                    color: #fff;
                }
            }

            &.disabled {
                icon {
                    background: $leftNavBorderColor;
                    pointer-events: none;
                    cursor: default;

                    mat-icon {
                        pointer-events: none;
                        cursor: default;
                    }
                }
            }
        }

        input {
            height: 30px;
        }
    }

    span.searchFilters {
        color: $matIconGreyColor;
        line-height: 42px;
    }

    span.searchFilters:hover {
        text-decoration: underline;
    }

    .processesViewHeader {
        .actionsListContainer {
            height: 42px;
            padding-right: 12px !important;
        }

        .processesHeaderMenu {
            padding: 0px 15px;
        }
    }

    .tooltip {
        pointer-events: none;
        position: absolute;
        top: -0.6em;
        display: none;
        visibility: hidden;
        opacity: 0;
        right: 36px;
        min-width: 500px;
        max-width: 550px;
        padding: 15px 20px;
        color: #2e2d2d;
        border-radius: 5px;
        white-space: normal;
        word-break: break-all;
        background: #fff;
        border: 2px solid white;
    }

    .tooltip:after {
        content: '';
        position: absolute;
        right: -26px;
        top: 7px;
        width: 2em;
        height: 1em;
        border-top: 10px solid transparent;
        border-left: 10px solid #f44336;
        border-bottom: 10px solid transparent;
        border-right: 12px solid transparent;
        pointer-events: none;
    }

    .tooltipOnRight:after {
        left: -26px;
        border-right: 10px solid #f44336;
        border-left: 12px solid transparent;
    }

    .revealTooltip {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    mat-icon.editInfoIcon:hover + .tooltip {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    .tooltip.tooltip_active_up {
        opacity: 1;
        margin-bottom: 5px;
        transition: all 0.2s ease;
    }

    .tooltip.tooltip_active_down {
        opacity: 1;
        margin-top: 5px;
        transition: all 0.2s ease;
    }

    .tooltip.out {
        opacity: 0;
        margin-top: -20px;
    }

    .messagePopup_error {
        position: relative;
        color: #dd2c00;
        cursor: pointer;
        display: inline-block;
        height: 24px;
        vertical-align: middle;
    }

    .messagePopup_error .tooltip {
        box-shadow: 0px 3px 1px -2px #e57373, -2px 1px 2px 0 #ef9a9a, 0 1px 5px 0 #ef9a9a;
        transition: visibility 0s linear 0.3s, opacity 0.3s linear;
    }

    .messagePopup_warning {
        position: relative;
        color: #ff9800;
        cursor: pointer;
        display: inline-block;
        height: 24px;
        vertical-align: middle;
    }

    .messagePopup_warning .tooltip::after {
        border-left: 10px solid #ffc20d;
    }

    .messagePopup_warning .tooltip {
        box-shadow: 0px 3px 1px -2px #ffc107, -2px 1px 2px 0 #ffc107, 0 1px 5px 0 #ffc107;
        transition: visibility 0s linear 0.3s, opacity 0.3s linear;
    }

    .navigatorMainSliderSelection {
        overflow: hidden;

        .collapseBtn {
            float: right;
        }
    }

    table {
        &.mat-table {
            tr.mat-header-row {
                th.matTableHeader.mat-header-cell {
                    min-width: 140px;
                    padding-left: 10px;
                    padding-right: 10px;

                    &.mat-column-table-actions {
                        max-width: 200px;
                    }

                    &.logMenuIcon {
                        min-width: unset;
                    }

                    mat-icon {
                        vertical-align: middle;
                        height: 17px;
                        width: 17px;
                        margin-right: 0;
                        line-height: 17px;
                        font-size: 15px;

                        &.vpn_key {
                            transform: rotate(45deg);
                        }
                    }

                    &.cdk-column-cancelAll.mat-column-cancelAll,
                    &.cdk-column-selectRecord.mat-column-selectRecord {
                        min-width: 56px;
                        width: 56px;
                    }
                }
            }

            tbody {
                tr.mat-row {
                    td.matTableCell.mat-cell {
                        max-width: 170px;
                        overflow-x: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding-left: 10px;
                        padding-right: 10px;

                        &.file-browse {
                            max-width: 280px !important;
                        }

                        &.mat-column-table-actions {
                            min-width: 230px;
                            width: 250px;
                        }

                        &.mat-column-displayDetail:hover {
                            text-overflow: unset;
                            overflow: hidden;
                            cursor: pointer;
                            white-space: pre-wrap;
                            padding: 10px 0px 10px 0px;
                            transition-duration: 0.2s;
                        }

                        &.mat-column-requestId:hover {
                            text-overflow: unset;
                            overflow: visible;
                            cursor: copy;
                            white-space: pre-wrap;
                        }

                        &.mat-column-checkSum:hover {
                            text-overflow: unset;
                            overflow: hidden;
                            cursor: pointer;
                            white-space: pre-wrap;
                        }

                        .oldAuditValue {
                            text-decoration: line-through;
                            margin-right: 5px;
                        }
                    }

                    &.disabled {
                        td.matTableCell.mat-cell {
                            color: grey;
                        }
                    }

                    &.isDeleted {
                        background: #f7bfa3;
                        text-decoration: line-through;

                        .oldAuditValue {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .selectedProcessAction {
        background: #00a651;
        color: #fff;

        &.mat-icon-button:not(.queryButton) {
            color: #fff;
        }
    }

    .selectedMenuActions {
        color: #00a651;
        font-weight: 600;
    }

    .notSelectedProcessAction {
        background-color: none;
    }

    .selectSearchFilter {
        margin: 0px 17px;
        // margin: -11px 17px -18px 17px;
    }

    .processesTableContainer,
    .transmissionsMainContainer {
        padding: 15px;
        height: 100%;
    }

    .transmissionsMainContainer {
        .processesRowInfoContainer {
            padding: 10px 10px 0 !important;
        }
    }

    .processesTableContainer .actionButtons,
    .inboundTransmisionTable .actionButtons,
    .auditListDataContainer .actionButtons {
        line-height: 22px;
        width: 22px;
        height: 22px;
    }

    .subProcessesInboxStyle {
        flex: 0 0 69% !important;
        width: 69% !important;
        max-width: 69% !important;
    }

    .processViewClsIcon {
        // float: right;
        // font-size: 0px;
        // background: gainsboro;
        height: 40px;
        // margin-top: -3px;
        margin-top: -1px;
        border-bottom: 1px solid $leftNavBorderColor;
    }

    .selectAllButnDiv {
        line-height: 40px;
        padding-right: 10px;
    }

    .fieldAttachmentContainer {
        min-height: 67.5px;
        display: inline-block;
        line-height: 67.5px;
        color: #9f9f9f;

        span {
            display: inline-block;
        }

        .fieldAttachmentLabel {
            vertical-align: super;
            margin-right: 20px;

            &.requiredAttachment {
                vertical-align: middle;
            }

            & .fieldAttachmentLabelText {
                line-height: 47px;
            }

            & .fieldAttachmentRequiredText {
                line-height: 20px;
                font-size: 12px;
            }
        }

        .fieldAttachmentIcon {
            cursor: pointer;
            position: relative;
            top: 8px;

            mat-icon {
                cursor: pointer;
            }
        }

        &.gridLabelContainer {
            display: inline-block;
            width: 100%;

            .fieldAttachmentLabel {
                width: calc(100% - 25px);
                margin-right: 0;
            }
        }
    }

    .relatedTagsSpan {
        padding-right: 0px;

        .material-icons {
            color: $matIconGreyColor;
            font-size: 15px;
            line-height: 1.4;
        }
    }

    .reviewPreCaptureAttrContainer .noTrasformationSelectedCls,
    .requestReviewDataContainer .noTrasformationSelectedCls {
        text-align: left;
        min-height: 35px;
    }

    .reviewPreCaptureAttrContainer .noTrasformationSelectedCls .renderContent,
    .requestReviewDataContainer .noTrasformationSelectedCls .renderContent {
        position: relative;
        top: 8px;
        left: 18px;
        transform: unset;
    }

    .noPadding {
        padding: 0px;
    }

    .noPaddingLeft {
        padding-left: 0px;
    }

    .noPaddingRight {
        padding-right: 0px;
    }

    .padddingBottom {
        padding: 26px 17px;
    }

    .noMargin {
        margin: 0px;
    }

    .noMarginLeft {
        margin-left: 0px;
    }

    .noMarginRight {
        margin-right: 0px;
    }

    .reviewTenantNamesDisplayContainer {
        /* margin-left: 25px; */
        max-height: 200px;
        overflow-y: auto;
    }

    .uploadFileName {
        width: 50%;
        margin-left: 15px;
    }

    .digitalSignDiv {
        margin-top: 15px;
        margin-left: -15px;
        padding: 15px 0;
        background: #f6f6f6;
        border-radius: 4px;

        .dscLabelRow {
            margin-left: 0;
            margin-right: 0;

            .labelDiv {
                align-self: center;
            }

            .userDscFileName {
                color: $taxillaDarkGreen;
                text-decoration: underline;
                cursor: pointer;
                padding: 0 0px 0 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 140px;
                display: inline-block;
                white-space: nowrap;
                margin-bottom: -5px;
            }
        }

        .dscChangeRow {
            margin-left: 0;
            margin-right: 0;

            .chngBtn {
                text-align: right;
            }
        }

        .dscUploadBtnRow {
            margin: -5px 0 0 0;

            .dscBrowseBtn {
                align-self: center;

                .btn {
                    margin-top: -10px;
                    cursor: pointer;
                    padding: 0;
                }
            }

            .dscUploadBtn {
                align-self: center;
            }

            .dscCancelBtn {
                align-self: center;
                text-align: right;
            }
        }

        .fileTypeSupport {
            color: $taxillaRedColor;
            padding: 5px 15px;
        }
    }

    .entityInfoIcon {
        float: right;
    }

    .processesCloseInstances {
        position: relative;
        top: 6px;
    }

    .actionButtons {
        .icon28 {
            line-height: 18px !important;
        }
    }

    .breadCrumbDiv {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 16px;
    }

    @media only screen and (min-width: 1030px) and (max-width: 1366px) {
        .breadCrumbDiv {
            max-width: 490px;
        }
    }

    .searchFocus {
        padding: 0 6px 0 6px;
        border: 1px solid lightgray;
        height: 30px;
        margin: 4px -4px 4px 8px;
    }

    span.textOverflow {
        max-width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
        vertical-align: middle;

        .inboundTransmissionNameText {
            max-width: calc(100% - 25px);
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
            vertical-align: middle;
        }
    }

    .actionCloseButton {
        .icon28 {
            margin-top: -15px !important;
            // margin-right: 12px !important;
            margin-right: 1px !important;
        }
    }

    .groupCheckboxContainer {
        width: 100%;
        min-width: 40vh !important;
        max-width: unset !important;

        .checkBoxContainer {
            padding-left: 10px;
            margin: 15px 0;
        }
    }

    .updateRequestModalClass {
        max-width: 620px;
    }

    .basicSearchContainer {
        position: relative;

        .basicSearchIcon {
            font-size: 19px;
            color: #f77e7e;
        }

        .basicSearchIcon:hover {
            background-color: #e9ecef5e;
        }
    }

    .recordDataHeader {
        .outlookSearchContainer {
            .outLookSearchHolder {
                background: unset !important;
            }
        }

        .closeIcon {
            position: relative;
            top: 7px;
        }

        .recordActionsCancelButton {
            .closeIcon {
                // top: 7px !important;
                top: 2.5px !important;
            }
        }
    }

    .custom-tooltip {
        text-transform: capitalize !important;
    }

    .activeMenuItem {
        background: $submitButtonColor;
        color: #fff;

        &.mat-menu-item:hover:not([disabled]) {
            background: $submitButtonColor;
            color: #fff;
        }
    }

    .wordBreak {
        word-wrap: break-word !important;
    }

    .cdk-virtual-scroll-orientation-vertical {
        .cdk-virtual-scroll-content-wrapper {
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            border: 1px solid $oddItemBgColor;
            width: auto;
        }
    }

    .notifyContentContainer {
        .eventTable {
            padding: 15px;
        }
    }

    .cdk-scroll-container {
        .cdk-virtual-scroll-viewport {
            .cdk-virtual-scroll-content-wrapper {
                max-width: 100%;
            }
        }
    }

    .mat-table {
        tr.example-element-row {
            &.matTableError {
                background: #f6c1ac;

                &:not(.example-expanded-row):hover,
                &.matTableWarning:hover {
                    background: #e54304 !important;
                    color: #fff;

                    .actionsMenu {
                        .actionButtons {
                            .matTableActionIcon {
                                color: #fff !important;
                            }
                        }
                    }

                    .actionsMenu:hover {
                        .actionButtons {
                            .matTableActionIcon {
                                color: #616161 !important;
                            }
                        }
                    }
                }

                &.matTableWarning {
                    background: #f6c1ac;
                }

                &:hover .mat-cell,
                &.matTableWarning:hover .mat-cell,
                &:hover .mat-footer-cell,
                &.matTableWarning:hover .mat-footer-cell {
                    color: #ffffff;
                }
            }

            &.matTableWarning {
                background: #ffeac4;

                &:not(.example-expanded-row):hover {
                    color: #fff;
                    background: #f5bc54;
                }
            }
        }
    }

    .inboxStyleFeedActionsContainer {
        .outlookSearchContainer {
            .mainSearchSearcher {
                width: unset;
            }

            .mainSearch {
                padding: 0 5px 0 0;
            }
        }
    }

    .completedWorkflow {
        color: $taxillaDarkGreen;
    }

    .currentWorkflow {
        color: black;
    }

    .skippedWorkflow {
        color: grey;
    }

    .notInitializedWorkflow {
        color: #ff4c3f;
    }

    span.material-icons {
        &.hasError {
            color: $taxillaRedColor;
        }

        &.hasWarning {
            color: $taxillaOrangeColor;
        }
    }

    .homeButton {
        margin-right: 5px;
        cursor: pointer;

        .mat-icon {
            font-size: 20px !important;
            color: #616161 !important;
            margin-top: 14px;

            &:hover {
                color: $backgroundGreen !important;
            }
        }
    }

    .reportsBreadCrumb {
        .homeButton {
            padding: 8px 0 0 15px;
        }

        .packagePageBreadcrumbContainer {
            margin-left: unset !important;
        }
    }

    material-group-checkbox {
        .materialGroupCheckboxContainer {
            line-height: 60px;

            button {
                width: 100%;
                border-bottom: 1px solid $lineColor;
                text-align: left;
                padding-left: 0;

                .groupCheckboxSelectedText {
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    white-space: nowrap;
                    max-width: calc(100% - 20px);
                    display: inline-block;
                }

                .dropdowns {
                    top: 50%;
                    position: absolute;
                    right: 17px;
                    transform: translateY(-50%);
                }

                &.descriptionButton {
                    width: 24px;
                    height: 24px;
                    line-height: 20px;
                    top: 50%;
                    position: absolute;
                    right: -5px;
                    transform: translateY(-50%);
                }

                .fulWidthMatMenuShowArrowIcon {
                    display: none;
                }

                &[aria-expanded='true'] {
                    .defaultDropdownIcon {
                        display: none;
                    }

                    .fulWidthMatMenuShowArrowIcon {
                        display: inline-block;
                    }
                }
            }

            .hintsContainer {
                line-height: 20px;
                font-size: 75%;
            }

            &.mat-error {
                padding-top: 10px;

                .hintsContainer {
                    border-top: 1px solid $taxillaRedColor;
                }
            }

            &.mat-warning {
                .hintsContainer {
                    border-top: 1px solid $taxillaOrangeColor;
                }
            }
        }
    }

    .fulWidthMatMenu.mat-menu-panel .mat-card {
        .tenantStructureCnt .levelOneTenants .searchTenants,
        .tenantFlatStructure .searchSubTenants {
            font-size: 13px !important;
        }
    }

    .closeIcon {
        color: $matIconGreyColor;
        cursor: pointer;
    }

    .mastersHeader {
        .closeIcon {
            position: relative;
            top: 7px;
            left: 5px;
        }
    }

    .transmissionsViewContainer {
        .closeIcon {
            position: relative;
            top: 2px;
        }
    }

    .processesActionDisplayHeader {
        .closeIcon {
            position: relative;
            top: 9px;
            right: 5px;
        }
    }

    .transmissionsActionDisplayHeader {
        .closeIcon {
            position: relative;
            top: 9px;
            right: 5px;
        }
    }

    .reportsMainHeader {
        .closeIcon {
            // position: relative;
            // top: 2px;
            // left: 2px;
            vertical-align: middle;
        }
    }

    .changeLogDataHeader {
        .closeIcon {
            position: relative;
            top: 3px;
        }
    }

    .matOptionHide {
        display: none !important;
    }

    .endOfEntityRecords {
        margin-top: 20px;
        text-align: center;
    }

    .selectSearchFilterOpt {
        .mat-option-text {
            max-width: calc(100% - 130px);
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
        }
    }

    .viewSearchRequestReports {
        width: inherit !important;
    }

    #viewSearchRequestBtn {
        background-color: #5c5c5c;
        color: #ffffff;

        button {
            background-color: #5c5c5c;
            color: #ffffff;
        }
    }

    .mat-simple-snackbar-action {
        color: unset !important;

        button {
            padding-left: 0 !important;
        }
    }

    .mat-progress-bar-fill::after {
        background-color: $taxillaDarkGreen !important;
    }

    .incomplateContext {
        pointer-events: none;
        opacity: 0.5;
    }

    .pointerEventNone {
        pointer-events: none;
        cursor: not-allowed !important;
    }

    // my styles
    .collapseContainer {
        .mat-expansion-panel-header {
            padding: 0 3px;
            height: 30px !important;
        }

        .mat-expansion-panel-header-title {
            font-size: 13px;
        }

        .mat-expansion-panel {
            border: none;
            box-shadow: none;
        }

        .mat-expansion-panel-body {
            padding: 0 20px 0px;
        }
    }

    .relatedBridgeAcrdCls {
        .material-icons {
            font-size: 16px;
            line-height: 1.25;
            margin-top: 32px;
            margin-left: 10px;
        }
    }

    .relatedBridgeSpan {
        margin-left: 41px;
        max-width: 124px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        position: relative;
        top: 4px;
        margin-right: 29px;
        margin-bottom: 25px;

        &.childAppName {
            top: 1px;
            margin-left: 10px;
        }
    }

    .relatedAppsMenu {
        padding: 10px;
        min-width: 230px !important;
        position: fixed;
        bottom: 70px;
        left: 5px;
        max-height: 500px !important;
        overflow: hidden !important;

        .menuTitle {
            font-size: 16px;
            font-weight: 600;
        }

        .searchTenantsDiv {
            .searchEntities {
                .searchEntitiesIcons {
                    font-size: 19px !important;
                }
            }
        }
    }

    .attributeFieldDiv {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 10px;
    }

    .workflowTextarea {
        textarea {
            height: 20px !important;
        }
    }

    .autoCompleteSearch {
        padding: 0 10px;
    }

    .matAutocompleteOptionsContainer {
        &.mat-autocomplete-panel.mat-autocomplete-hidden {
            visibility: visible;
        }

        .noOptionsFound {
            margin: -10px 10px 10px;
        }
    }

    .eventLogHedderDiv {
        padding: 15px;
    }

    .readOnlyFieldValues {
        grid-field {
            line-height: 24px;

            .fieldAttachmentContainer {
                min-height: 24px;
                line-height: 24px;

                .fieldAttachmentLabel {
                    display: none;
                }

                .fieldAttachmentIcon {
                    height: 24px;
                }
            }
        }
    }

    // .loaderBlock.isLoading {
    //     background: -webkit-gradient(linear, right top, left top, from(#d0d0d0), to(#9e9e9e));
    //     background: linear-gradient(270deg, #d0d0d0, #9e9e9e);
    //     background-size: 400% 400%;
    //     -webkit-animation: GradientDualToneAnimation 2s ease infinite;
    //     animation: GradientDualToneAnimation 2s ease infinite;
    //     text-indent: 100%;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     display: inline-block;
    //     vertical-align: middle;
    //     text-decoration: none !important;
    //     min-height: 16px;
    // }

    .loaderBlock.isLoading {
        background: repeating-linear-gradient(to right, #fff 0%, #d0d0d0 100%);
        background-size: 200% 200%;
        background-position: 0 100%;
        -webkit-animation: gradient 2s ease infinite;
        animation: gradient 2s ease infinite;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none !important;
        min-height: 16px;
    }

    @-webkit-keyframes gradient {
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: -200% 0;
        }
    }

    @keyframes gradient {
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: -200% 0;
        }
    }

    .tenantIcon {
        .material-icons-outlined {
            font-size: 18px !important;
        }
    }

    .word-break-all {
        word-break: break-word !important;
    }

    .bulkActionsMenu {
        width: inherit;
        border: 1px solid $taxillaDarkGreen;
        background: $taxillaDarkGreen;
        color: white;
        border-radius: 0;
        padding: 0 9px;
        margin-left: 5px;
    }

    .bulkWorkflowAction button {
        width: inherit !important;
    }

    .homeAppNewText {
        line-height: 20px;
        display: inline-block;
        font-size: 11px;
        vertical-align: text-top;
        height: 20px;
        padding: 0 10px;
        margin: 5px 0 0px 10px;
        background: #fa8b3b;
        color: #fff;
        border-radius: 2px;
    }

    #homePage {
        background: #efefef;

        .processAppsSearchContainer {
            text-align: right;
            margin: 0px 15px 0px 15px;
            padding: 0;
            height: 110px;
            overflow: hidden;
            padding-top: 16px;

            .processAppsSearch {
                width: 100%;
                text-align: right;
                float: right;
                padding: 0 10px 10px 10px;
                background: #fff;
                border: 1px solid transparent;

                .mat-form-field-wrapper {
                    padding-bottom: 0;

                    .mat-form-field-underline {
                        background-color: #bfb5b5;
                        bottom: 0;
                    }
                }

                mat-icon {
                    font-size: 24px !important;
                }

                .mat-form-field-suffix {
                    padding: 10px 0px;
                }

                .filterSpanDiv:hover {
                    color: #09a651;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .filterSpanDiv {
                    line-height: 1.8;
                }

                .clearSpanDiv {
                    color: $taxillaRedColor;
                    cursor: pointer;
                }
            }
        }

        .homePageLinksContainer {
            padding: 0;
            padding-left: 15px;
        }

        .homePageEventsContainer {
            padding: 0;
            padding-right: 15px;
            margin-bottom: 15px;
        }

        .homePageAppsProcessesContainer {
            padding: 0;
            margin: 0 5%;
            width: 90%;
            flex: 0 0 90%;
            max-width: 90%;
        }

        .homeAppProcessesTitleText {
            display: inline-block;
            font-size: 16px;
            font-family: $fontFamily;
            color: #00a651;
            padding-left: 20px;
            font-weight: 600;

            .homeAppProcessesNewText {
                line-height: 20px;
                display: inline-block;
                font-size: 11px;
                vertical-align: text-top;
                height: 20px;
                padding: 0 10px;
                margin: 0px 0 0px 10px;
                background: #fa8b3b;
                color: #fff;
                border-radius: 2px;
            }
        }

        .homeProcessesAppContainer > mat-card.mat-card {
            padding: 0;
            border-radius: 0px !important;
        }

        .homeProcessesAppContainer {
            margin: 10px 15px;

            .homeAppProcessesTitle.isLoading {
                min-height: 60px;
            }

            .homeAppProcessesTitle {
                margin-bottom: 0;
                padding: 2px 0;

                .homeAppProcessesTitleText.loaderBlock.isLoading {
                    min-height: 20px;
                    min-width: 200px;
                    text-indent: 0;
                    float: left;
                    margin-left: 10px !important;
                    margin-top: 12px !important;
                }

                .homeAppProcessesTitleText {
                    display: inline-block;
                    font-size: 16px;
                    font-family: $fontFamily;
                    color: #00a651;
                    padding-left: 20px;
                    font-weight: 600;

                    .homeAppProcessesNewText {
                        line-height: 20px;
                        display: inline-block;
                        font-size: 11px;
                        vertical-align: text-top;
                        height: 20px;
                        padding: 0 10px;
                        margin: 0px 0 0px 10px;
                        background: #fa8b3b;
                        color: #fff;
                        border-radius: 2px;
                    }
                }

                .homeAppProcessesLastModified {
                    display: inline-block;
                    font-size: 12px;
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    color: $significantRegFontColor;
                    font-weight: 500;
                }

                .homeAppProcessesLastModified.loaderBlock.isLoading {
                    width: 280px;
                    min-height: 16px;
                    margin-right: -5px;
                }
            }

            .homeAppProcessesContent {
                padding: 10px;
                padding-top: 0;

                .card {
                    border: none;
                }

                .homeAppProcessMainContainer {
                    .matAccordionContainerDiv {
                        margin-top: 0px;
                    }

                    .materialAccordionHeader {
                        .appActionsContainer {
                            span {
                                font-size: 15px;
                                // font-weight: bold;
                                color: $significantRegFontColor;
                            }

                            .participantAppTitle {
                                color: $lightGreyColor;
                                font-size: 13px;
                            }
                        }
                    }

                    .materialAccordionHeaderDescription {
                        span {
                            font-size: 13px;
                            font-weight: normal;
                            cursor: pointer;
                            color: $taxillaDarkGreen;

                            a {
                                text-decoration: none;
                                color: inherit;
                            }

                            a:hover {
                                text-decoration: none;
                                color: inherit;
                            }
                        }

                        span:hover {
                            border-bottom: 1px solid $taxillaDarkGreen;
                        }
                    }

                    .homeAppProcessBodyMainContainer {
                        margin-top: 0px;

                        &.packageApp {
                            margin-top: 0;
                        }

                        .mat-stepper-horizontal {
                            background: none;
                        }

                        .homeAppTitle {
                            font-size: 14px;
                            font-weight: 600;
                            color: $significantRegFontColor;
                        }

                        mat-step-header {
                            padding: 20px 0 10px 5px;
                        }

                        .mat-vertical-content-container {
                            margin-left: 17px;
                        }

                        .mat-stepper-vertical-line::before {
                            border-left-color: $submitButtonColor;
                            border-left-width: 2px;
                        }

                        .mat-step-icon {
                            background-color: $submitButtonColor;
                        }

                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
                        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after,
                        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
                            top: 32px;
                        }

                        .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
                            top: 32px;
                        }

                        mat-horizontal-stepper {
                            .mat-step-icon {
                                background: none;
                                color: #d7d7dd;
                            }

                            mat-icon.completed {
                                color: $submitButtonColor;
                            }
                        }

                        .mat-vertical-stepper-content .mat-vertical-content::before {
                            content: '';
                            position: absolute;
                            top: -16px;
                            bottom: 0;
                            left: 0;
                            border-left-width: 2px;
                            border-left-style: solid;
                            color: $submitButtonColor;
                            border-left-color: $submitButtonColor;
                        }

                        .homeAppProcessWorkflowContainer {
                            font-size: 13px;
                            color: $significantRegFontColor;
                            padding: 0px;
                            margin-top: -25px !important;
                            position: relative;

                            @extend %appProcessComponentStyles;

                            a:hover,
                            a:focus {
                                text-decoration: none;
                            }
                        }
                    }

                    .alternativeColorDiv .mat-expansion-panel {
                        background: #f7f7f9;
                        box-shadow: none;
                    }

                    .mat-expansion-panel {
                        background: #f4fef9;
                        box-shadow: none;
                    }

                    .mat-expansion-indicator {
                        display: none;
                    }

                    .mat-expansion-panel-header {
                        padding: 0px 10px 25px 10px;
                    }

                    .mat-expansion-panel-header-description {
                        text-align: right;

                        .materialAccordionHeaderDescription {
                            text-align: right;
                            width: 100%;
                        }
                    }

                    .mat-expansion-panel-body {
                        padding: 0 10px 5px;
                    }

                    .homeAppProcessContainer {
                        mat-vertical-stepper {
                            .homeAppTitle {
                                font-size: 14px;
                                font-weight: 600;
                                color: $significantRegFontColor;
                            }

                            mat-step-header {
                                padding: 20px 0 10px 5px;
                            }

                            .mat-vertical-content-container {
                                margin-left: 17px;
                            }

                            .mat-stepper-vertical-line::before {
                                border-left-color: $submitButtonColor;
                                border-left-width: 2px;
                            }

                            .mat-step-icon {
                                background-color: $submitButtonColor;
                            }

                            .mat-vertical-content {
                                padding: 0 0 0 26px;

                                mat-horizontal-stepper {
                                    .mat-step-icon {
                                        background: #fff;
                                        color: #d7d7dd;
                                    }

                                    mat-icon.completed {
                                        color: $submitButtonColor;
                                    }
                                }
                            }

                            .mat-vertical-stepper-content .mat-vertical-content::before {
                                content: '';
                                position: absolute;
                                top: -16px;
                                bottom: 0;
                                left: 0;
                                border-left-width: 2px;
                                border-left-style: solid;
                                color: $submitButtonColor;
                                border-left-color: $submitButtonColor;
                            }
                        }

                        .appActionsContainer {
                            button.mat-raised-button {
                                margin: 0 10px;
                                height: 28px;
                                line-height: 25px;
                                font-size: 13px;
                                border-radius: 14px;
                                background: #fff;
                                box-shadow: none;
                                color: #000;
                                border: 1px solid $submitButtonColor;
                            }

                            button.mat-raised-button:hover {
                                background: $submitButtonColor;
                                color: #fff;
                            }

                            button.mat-raised-button:first-child {
                                margin-left: 0;
                            }
                        }

                        .homeAppHorizontalBarContainer {
                            position: absolute;
                            content: '';
                            border-top: 2px solid $submitButtonColor;
                            left: -45px;
                            top: 10px;
                            width: 35px;
                        }

                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
                        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after,
                        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
                            top: 32px;
                        }

                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after {
                            border-top-width: 0px !important;
                        }

                        .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
                            top: 32px;
                        }

                        .appFilingAttributeContainer {
                            margin: 5px 0;

                            label {
                                font-weight: 600;
                            }

                            label::first-letter {
                                text-transform: uppercase;
                            }

                            span {
                                margin: 0 5px;
                            }
                        }

                        .homeAppProcessWorkflowContainer {
                            font-size: 13px;
                            font-weight: normal;
                            position: relative;
                            margin-top: -25px !important;
                            @extend %appProcessComponentStyles;
                        }

                        .workflowStagesContainer {
                            .mat-horizontal-stepper-header-container {
                                overflow-x: auto;

                                mat-step-header {
                                    min-width: 100px;
                                    padding-bottom: 10px;
                                    padding-left: 5px;
                                    padding-right: 5px;
                                }

                                .workFlowName {
                                    font-size: 11px;
                                    color: #636363;
                                }

                                .workFlowName.completed {
                                    color: $submitButtonColor !important;
                                }

                                &:hover {
                                    .homePageStepperWorkFlowName {
                                        text-decoration: none;
                                    }
                                }
                            }

                            .mat-horizontal-content-container {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .mainSearchFilters.filterVisible {
            width: 50%;
            height: 220px;
            opacity: 2;
            background: #fff;

            .srchHeading {
                position: relative;
                height: 42px;
                padding: 10px 10px 10px 20px;
                border-bottom: 1px solid $headerItemBorderColor;

                .srchHeadingLabel {
                    font-size: 15px;
                }
            }

            .mat-radio-checked .mat-radio-label-content {
                font-weight: 600;
            }

            .filterSelection {
                padding-left: 20px;
            }

            .searchFilterContainer label,
            .requestFilterContainer label,
            .defaultFilterContainer label {
                font-weight: 600;
            }

            .mat-tab-label {
                opacity: 1;
                font-weight: bold;
                color: $matIconGreyColor;
            }

            .mat-tab-label.mat-tab-label-active {
                color: #333;
            }

            .mat-tab-label:hover {
                color: #333;
            }

            .actionBtnsSection {
                padding: 10px 20px;
                border-top: 1px solid $headerItemBorderColor;
            }

            .tagSearchMainDiv {
                padding: 10px 20px;

                .radioButtonGroup {
                    .mat-radio-group {
                        display: inline-block;

                        .mat-radio-button {
                            padding-right: 50px;
                        }
                    }
                }
            }

            .searchFooterDiv {
                padding-top: 20px;

                .cancelBtn {
                    padding-left: 20px;
                }
            }
        }

        .closeOutlookSearch {
            position: absolute;
            right: 10px;
            top: 5px;
        }

        .closeOutlookSearch span.material-icons {
            font-size: 16px;
            background: #616161 !important;
            color: #fff;
            border-radius: 50%;
            padding: 2px;
            margin: 5px;
        }
    }

    %appProcessComponentStyles {
        .appRequestStatus {
            // color: $submitButtonColor;
            .inprogress {
                color: $inProgressColor;
            }

            .terminated {
                color: $lightRedColor;
            }

            .completed {
                color: $submitButtonColor;
            }
        }

        .processInfoMainHeading {
            font-weight: bolder;
            font-size: 14px;
            margin-right: 30px;
            color: $placeholderHintColor;
        }

        .processInfoPara {
            color: $placeholderHintColor;
            // margin-left: 15px;
        }

        .appProcessReportingAttributes.loaderBlock.isLoading {
            width: 155px;
            min-height: 16px;
        }

        .lastProcessedTime.loaderBlock.isLoading {
            min-width: 159px;
            min-height: 16px;
        }

        .processInfoTitle {
            font-weight: bold;
            margin-right: 5px;
            display: inline-block;
        }

        .moreHistoryPara {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .processInfoPara:last-child {
            margin-bottom: 5px;
        }
    }

    &.fileDragDetected {
        * {
            color: $fileDragColor;
            border-color: $fileDragColor;

            .mat-form-field .mat-form-field-infix {
                border-color: transparent;
            }

            img {
                opacity: $fileDragOpacity;
            }

            button {
                &.mat-button,
                &.mat-icon-button,
                &.mat-button-base {
                    opacity: 0.5;
                }
            }

            mat-icon.mat-icon {
                opacity: $fileDragOpacity;
            }

            .latestStatusMessage {
                opacity: $fileDragOpacity;
            }

            .instanceBreadcrumb .packageDetailBreadcrumbItem .packageDetailBreadcrumbText {
                opacity: $fileDragOpacity;
            }

            .mat-card {
                box-shadow: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            }

            #processView .processViewContainer .entitiesListColumn {
                box-shadow: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            }

            .fileDropZoneContainer {
                display: block;
                position: relative;
                top: 72%;
                left: 0%;
                transform: scale(1.5);
                padding-right: 14px;
                opacity: 0.5;
                z-index: 9999;
                margin-right: 70px;

                .fileDropZoneText {
                    color: #212529;
                    padding: 0px 28px;
                    border: 1px dashed $taxillaDarkGreen;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 35px;

                    &:hover,
                    &.fileHovered {
                        -webkit-animation: border-dance 4s infinite linear;
                        animation: border-dance 4s infinite linear;
                        height: 36px;
                        width: 138px;
                        background: linear-gradient(90deg, $taxillaDarkGreen 50%, transparent 50%),
                            linear-gradient(90deg, $taxillaDarkGreen 50%, transparent 50%),
                            linear-gradient(0deg, $taxillaDarkGreen 50%, transparent 50%),
                            linear-gradient(0deg, $taxillaDarkGreen 50%, transparent 50%);
                        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
                        background-size: 12px 2px, 12px 2px, 2px 12px, 2px 12px;
                        background-position: 0px 0px, 140px 38px, 0px 38px, 140px 0px;
                        line-height: 36px;
                        border: 0;
                    }
                }
            }

            .fileBrowseContainer {
                display: none;
                opacity: 1;
                color: #212529;
            }

            .modal-dialog,
            .modal-content {
                border: none;
            }
        }
    }

    @-webkit-keyframes border-dance {
        0% {
            background-position: 0px 0px, 140px 34px, 0px 70px, 136px 0px;
        }

        100% {
            background-position: 140px 0px, 0px 34px, 0px 0px, 136px 70px;
        }
    }

    @keyframes border-dance {
        0% {
            background-position: 0px 0px, 140px 34px, 0px 70px, 136px 0px;
        }

        100% {
            background-position: 140px 0px, 0px 34px, 0px 0px, 136px 70px;
        }
    }

    .fileDropZoneContainer {
        display: none;
    }

    .selectGroupClass {
        font-size: 13px !important;

        .mat-optgroup {
            .mat-optgroup-label {
                margin-bottom: -8px !important;
                height: 2.8em !important;
            }

            .mat-option {
                height: 2.8em !important;
            }
        }

        .selectSearchFilter {
            margin: 0px 17px -13px 17px;
        }
    }

    .reviewHeaderDiv {
        font-weight: 600;
        padding: 10px 0px 10px 0px;
    }

    .zeroPaddingLeft {
        padding-left: 0 !important;
    }

    .homePageColumnsContainer {
        flex-wrap: unset !important;
    }

    .disabled {
        background-color: $matIconGreyColor !important;
        pointer-events: none;
        cursor: default;

        &:hover {
            background-color: $matIconGreyColor !important;
        }

        &.mat-row {
            background-color: unset !important;
        }
    }

    .tagFieldInput {
        color: $taxillaDarkGreen !important;
    }

    .highlightSuggestedOption {
        color: $taxillaDarkGreen;
    }

    .suggestionSearchBorder {
        border: 1px solid $taxillaDarkGreen;
        border-radius: 7px;
        padding: 2px;
    }

    .tagSearchSuggestions {
        position: absolute;
        top: 17.5px;
        background: white;
        z-index: 1;
        left: -4px;
        min-height: 41px;
        overflow: auto;
        min-width: 202px;
        padding: 5px;

        .suggestion {
            padding: 7px 10px;

            &:hover {
                background: $canvasGridColor;
            }
        }
    }

    .tagFieldValue {
        text-transform: none !important;
        color: $taxillaDarkGreen;
    }

    .prefixCntr {
        .fullRule {
            padding: 5px;
            margin-right: 5px;
            background: #f9f9f9;
        }
    }

    @media only screen and (min-width: 1370px) and (max-width: 1700px) {
        .outlookInboxSearch {
            min-width: 213px;
        }
    }

    @media only screen and (min-width: 1030px) and (max-width: 1366px) {
        .outlookInboxSearch {
            min-width: 109px;
        }
    }

    @media only screen and (min-width: 1700px) and (max-width: 1800px) {
        .outlookInboxSearch {
            min-width: 253px;
        }
    }

    .recordLevelSearcher {
        min-width: 500px;

        .mat-form-field-suffix {
            border-top: 0.84375em solid rgba(0, 0, 0, 0);
            padding: 0.4375em 0;
        }

        .searchClose {
            width: 26px !important;
        }
    }

    .tagFieldDelete {
        font-size: 15px;
        position: absolute;
        top: -9px;
        right: 0;
        color: red;
    }

    // #bulkWorkflowUserAction,
    // #workflowUserAction {

    //     .modal-dialog {
    //         width: 80%;
    //         max-width: 100%;

    //         .modal-body {
    //             margin: 0;

    //             .workflowFormFieldsContainer {
    //                 display: block;
    //                 width: 100%;
    //             }

    //             .workflowCommentsContainer {
    //                 width: 33.33334%;
    //             }
    //         }
    //     }

    //     .tagFieldOperator:hover .tagFieldId:hover {
    //         color: $taxillaDarkGreen;
    //     }
    // }

    .processSearch {
        border: 1px solid #00a651 !important;
        transition: border 0.5s ease;
    }

    .mainSearchFilters {
        width: 0;
        height: 0;
        opacity: 0;
        font-size: 14px;
        overflow: hidden;
        position: absolute;
        top: 15px;
        z-index: 2;
        right: 15px;
        transition: 0.5s;
    }

    .homePageAppSearch .mat-form-field-underline {
        display: none !important;
    }

    .homePageAppSearch .mat-form-field-infix {
        border: 0px;
        padding: 10px 0px;
        display: inherit;
    }

    .homePageAppSearch .mat-form-field-label-wrapper {
        top: -5px;
    }

    .auditFormFields {
        .fieldInReadOnly {
            height: 36px;
            width: 100%;

            .readOnlyFieldValues {
                display: flex;
                margin: 5px 0;

                .readOnlyLabel {
                    color: $placeholderHintColor;
                    flex: 0 0 40%;
                    max-width: 40%;
                    width: 40%;
                    font-size: inherit;
                    text-align: right;
                    line-height: 24px;
                    padding-right: 20px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .readOnlyValue {
                    font-size: 13px;
                    line-height: 24px;
                    white-space: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    flex: 0 0 60%;
                    max-width: 60%;
                    width: 60%;
                    padding-right: 15px;
                }
            }
        }
    }

    .processAppsMainContainer .noRecordMessage {
        margin: 25px;

        .navigateToLink {
            cursor: pointer;
            color: $taxillaDarkGreen;

            &:hover {
                color: $taxillaDarkGreen;
            }
        }
    }

    .noRecordMessage {
        font-size: 14px;
        text-align: center;
    }

    .processoutlookSearchContainer {
        top: 4.5px;
        margin: 4px;
    }

    .processDeleteRecordBtn {
        top: 10px !important;
    }

    .mat-snack-bar-container {
        max-width: 50vw !important;
        margin: 55px !important;
    }

    .addDataBtnCls {
        margin: 0px 10px !important;
    }

    .initiateBtnCls {
        margin: 0px 0px 0px 15px !important;
    }

    .processesRecordHeader {
        padding: 2.5px 15px !important;
    }

    .entityRecordActionButtons {
        margin: 0 0 0px 10px;
    }

    .mat-form-field-suffix {
        button.mat-button.mat-icon-button.mat-button-base {
            margin-left: 5px;
        }
    }

    /* IE10+ specific styles go here */
    @media all and (-ms-high-contrast: active), all and (-ms-high-contrast: none) {
        .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }

        .fulWidthMatMenu {
            max-width: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        .fulWidthMatMenu :not(:empty).mat-menu-content {
            max-height: none !important;
        }

        .slimScrollBar {
            display: block !important;
        }

        .settingsCurtainMenu {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        table.mat-table tbody tr.mat-row td.matTableCell.mat-cell {
            overflow: hidden !important;
        }

        table.mat-table tr.mat-header-row th.matTableHeader.mat-header-cell {
            overflow: hidden !important;
        }

        .actionsMenu .actionButtons:hover {
            background: none !important;
        }

        #processView .processViewContainer .entityMainDataContainer .entityDataContainer .recordDataHeader .recordDataEntityName {
            -webkit-box-flex: 0 !important;
            -ms-flex: none !important;
            flex: none !important;
            min-width: 35% !important;
        }

        #processView .processViewContainer .entityMainDataContainer .entityDataContainer .recordDataHeader .recordActionButtonForStyle {
            top: auto;
        }

        .entityMainDataContainer .recordDataMainContainer .recordTable {
            min-width: 100% !important;
            -ms-flex-preferred-size: 100% !important;
            flex-basis: 100% !important;
        }

        .mat-progress-spinner circle,
        .mat-spinner circle {
            stroke: #00a651;
        }

        .recordDataHeader .outlookSearchContainer .outLookSearchHolder {
            background: none !important;
        }

        #processView
            .processViewContainer
            .entityMainDataContainer
            .entityDataContainer
            .recordDataHeader
            .recordDataActionsContainer
            mat-icon {
            width: auto !important;
        }

        .mainSearchSearcher span.ieStyle {
            width: 50% !important;
        }

        .matTableCell.alignCenter {
            text-indent: 9px !important;
        }

        .processesViewDetailBodyContainer {
            height: 80vh !important;
        }

        .inboundRightHeader .basicSearchContainer {
            width: 23% !important;
        }

        #processView .processViewContainer .entityMainDataContainer .entityDataContainer .recordDataMainContainer {
            height: 80vh !important;
            min-width: 100% !important;
        }

        .processViewDirectiveContainer .inboxStyle .processesViewAppName {
            -ms-flex-preferred-size: 43% !important;
            flex-basis: 43% !important;
        }

        #processView
            .processViewContainer
            .entityMainDataContainer
            .entityDataContainer
            .recordDataMainContainer
            .dataDisplayMainContainer
            .recordDataDisplayMainContainer.isInReadOnlyMode {
            min-width: 100% !important;
        }

        .relatedAppsDiv .relatedSidebarApps span.material-icons,
        .relatedTenantsDiv .relatedSidebarApps span.material-icons {
            padding-right: 25px;
        }

        #processView .processViewContainer .filteredRequestsContainer.showingFilteredProcesses {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% - 54px);
        }

        .homeButton .mat-icon {
            margin-top: 16px;
        }

        .reportsBreadCrumb {
            .packageDetailBreadcrumbText {
                margin-left: auto !important;
            }
        }

        .requestReviewDataContainer .noTrasformationSelectedCls .renderContent,
        .reviewPreCaptureAttrContainer .noTrasformationSelectedCls .renderContent {
            -webkit-transform: translate(0px, 0px) !important;
            transform: translate(0px, 0px) !important;
        }

        .inboxStyle .processesViewHeader .inboxIconsContainer {
            width: 100% !important;
        }

        .successPanelClass .mat-button-wrapper {
            color: white !important;
        }

        .ieStyleCloseButton {
            position: relative !important;
            top: -6px !important;
        }

        .mastersContainerRow
            .masterData
            perfect-scrollbar
            .ps
            .ps-content
            material-table
            .matTableContainer
            .tableContainerWithoutPaginator {
            -ms-overflow-style: auto;
        }

        .app-table-virtual-scroll-orientation-vertical .app-table-virtual-scroll-content-wrapper {
            width: 100%;
            overflow: auto;
            -ms-overflow-style: auto;
        }
    }

    /* IE10+ specific styles go here */

    // .rootOrgName {
    //     display: flex;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     width: 100%;

    //     .orgName {
    //         margin-right: 2px;
    //         max-width: 25%;

    //         .rootOrg {
    //             white-space: nowrap;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             max-width: 100%;
    //         }
    //     }

    //     .rootOrgId {
    //         display: flex;
    //         color: #a2a2a2;
    //         margin-left: 2px;
    //     }
    // }

    .organizationId {
        font-weight: 100;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 15px;
    }

    .pull-left {
        float: left;
    }

    .ps__thumb-y {
        background-color: #827f7f !important;
        width: 8px !important;
    }

    .ps__rail-y {
        width: 13px !important;
    }

    .radioGroup {
        .requiredCls {
            top: calc(100% - 5.291667em) !important;
        }
    }

    .requiredCls {
        top: calc(100% - 5.291667em) !important;
        padding-left: 86px !important;
    }

    .isAuditSelected {
        color: #00a651;
    }

    .selectedMasterNameCls {
        display: inline-block;
        position: relative;
        top: 0px;
        line-height: unset;

        .settingsNameText {
            top: 0px !important;
        }
    }

    .allMastersDropdownContainer {
        float: right;
        position: relative;
        top: -9px;
        right: 5px;
    }

    .multiRecordEditModal.matDialogContainer {
        width: 90vw;
        max-width: 90vw !important;

        &.singleColumnUpdate {
            width: 50vw;
            max-width: 50vw !important;
        }
    }

    &.onNewUI {
        #processView {
            .processViewContainer {
                .entitiesListColumn {
                    .statusGroupsContainer.instanceSelected {
                        display: none;
                    }

                    .entitiesListContainer {
                        padding: 0;

                        .entitiesListMainContainer {
                            padding: 0;

                            .entityListItemContainer {
                                &:hover,
                                &.activeEntity {
                                    background: transparent;
                                }
                            }
                        }
                    }
                }

                .processLeftNav {
                    .fixToBottom {
                        display: none;
                    }
                }

                .newProcessButton {
                    display: none;
                }

                .filteredRequestsContainer.showingFilteredProcesses.outOfContext,
                .entityDataColumn.outOfContext {
                    padding: 44px 0 0 104px;
                }
            }
        }

        .menuButton .mat-button-ripple {
            top: 2px;
            bottom: 2px;
            left: 5px;
        }
    }
}

.breadCrumbContainer {
    padding: 10px 15px 15px 0 !important;
}

.appHomeButn {
    .mat-icon {
        margin: 0px !important;
    }
}

.noRecords {
    padding: 54px;
}

.multipleSearchReportsDownloadDialog {
    .reportRow {
        padding-bottom: 5px;
        padding-left: 5px;

        &:hover {
            background: #f6f6f6;
        }

        .closeIcon {
            position: relative;
            top: 5px;
            left: 5px;
        }
    }
}

.filingAttributeRadioBtnCls {
    margin-left: 5px;
}

.transmissionIdSearchContainer {
    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float.forcedPlaceholder .mat-form-field-label {
        transform: none !important;
        width: 0;
    }

    .searchModeIcon {
        color: $lightRedColor;
        position: relative;
        top: 3px;
    }

    input {
        position: relative;
        top: -2px;
    }
}

.auditListIconClass {
    color: #868686;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: rgba(0, 0, 0, 0.87);
}

td.mat-cell.cdk-cell.matTableCell.cdk-column-displayDetail.mat-column-displayDetail {
    max-width: 460px !important;
}

.downloadBulkReportInfoCls {
    padding-left: 15px;
}

.checkBoxSpanCls {
    padding-left: 5px;
    position: relative;
    top: 1px;
}

.bulkReportSearchSpan {
    padding-left: 5px;

    .bulkinfoIcon {
        cursor: pointer;
        font-size: 20px;
        position: relative;
        top: 6px;
    }
}

.mainSearchFilters.homePageSearchFilter {
    top: 15px !important;
    right: 15px !important;
}

// specific target[only for masterdata virtual table]
.masterData perfect-scrollbar .ps-content app-virtual-table app-table-virtual-scroll-viewport .app-table-virtual-scroll-content-wrapper {
    width: 100%;

    table {
        display: block;
        /* display block on table element with 100% width on parent element doesn't allow scroll behaviour*/

        // hack for full width
        thead,
        tbody,
        tfoot {
            display: table;
            width: 100%;
        }
    }
}

.cancelProcessViewModal {
    width: 40%;
}

.gridFieldModal {
    width: 90%;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // -webkit-transform: translateX(-50%) translateY(-50%) !important;
    // -ms-transform: translateX(-50%) translateY(-50%) !important;
    // transform: translateX(-50%) translateY(-50%) !important;
    // margin: 0;
    // line-height: 4.5;

    button.actionButtons {
        width: 24px;
        height: 24px;
        line-height: 21px;
    }

    material-table table tr .mat-column-firstRowColumn {
        text-align: center;
        background-color: $headerColor;
    }
}

.allMastersMatMenuButton {
    margin: 0px !important;
}

.mat-badge-medium:not(.ignore) {
    .mat-badge-content {
        width: 30px;
    }
}

.mat-badge-medium.mat-badge-above:not(.ignore) {
    .mat-badge-content {
        top: -17px;
    }
}

.mat-badge-medium.mat-badge-after:not(.ignore) {
    .mat-badge-content {
        right: -29px;
    }
}

.mat-badge-content {
    border-radius: 5px;
}

.auditFormFieldsDialog {
    width: 70%;
}

.downloadReportDropdownContainer {
    height: 280px !important;

    .completeInstanceReportContainer {
        margin-top: 10px;
    }
}

mat-hint,
mat-error {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.collaborationShareContainer {
    padding: 20px;
}

.ngx-material-timepicker-toggle svg {
    width: 20px;
    height: 18px;
    color: #ccc;
    position: relative;
}

.entitySearch {
    .mainSearch,
    .mainSearchInput input {
        padding: 5px !important;
    }
}

.collaboratorsListDialog {
    width: 60%;
}

.timePickerContainer {
    button {
        padding: 0px !important;
    }
}

.processesDateFilter {
    cursor: pointer;
    margin-right: 20px;
    z-index: 102;

    .mainSearchFilters.filterVisible {
        height: 45%;
        width: 50%;
        z-index: 101;
    }

    .selectedFilterContainer {
        height: calc(100% - 110px);
    }
}

.allReportProcessesPage {
    .mainSearchFilters.filterVisible {
        z-index: 999 !important;
    }
}

.searchModeSuffix {
    font-size: 14px;
    padding: 0;
}

.clearSearchSuffix {
    color: #da534e;
    font-size: 14px;
    padding: 0;

    &:hover {
        border-bottom: 1px solid #da534e;
    }
}

app-new-process {
    .dowmtimeMessage {
        margin: 0 1% 10px;
    }
}

.entityDataColumn {
    .dowmtimeMessage {
        margin: 0 0 0.7em;
    }
}

.eventLogNotifications {
    min-width: 65%;
}

.listCollboratorsBtn {
    position: relative;
    top: 2px;

    span.material-icons {
        font-size: 24px !important;
    }
}

.allMastersList {
    max-height: 41vh;
    min-width: 268px;
    max-width: 270px;
    overflow-x: hidden;

    .activeMasterItem {
        background: #e2e2e2;
    }
}
